import React, {
	createContext,
	useReducer,
	useContext,
	useCallback,
	useEffect,
	useMemo
} from 'react';

import { useHistory } from 'react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany, getCompanyId } from 'modules/user/selectors';
import Actions from 'store/actions';
import {
	appReducer,
	CaseType,
	EntityPage,
	EntityPagePayload,
	HasUnread,
	HasUnreadPayload,
	initialState,
	RegisterInfo,
	SelectedCaseFilterPayload,
	SelectedCaseFilter,
	AreUnreadsFetchedPayload,
	AreUnreadsFetched,
	unreadLoadingError,
	UnreadLoadingErrorPayload,
	OpenCaseCreatePopup,
	SelectedStatus,
	StatusChangePayload,
	StatusPriorityMessagePopupType,
	GroupSearchOpenType,
	StatusReverseState,
	SettingsDataType,
	ChatsMentionObject,
	ScheduledMessageIdsObjType,
	SetLanguagePayloadType
} from './reducer';
import { EntityDraft } from '../../pages/GroupChat/types';
import { IRootState } from '../interfaces';
import { FilterTemplateType } from '../typings';
import config from '../../config';
import { UserRoles } from '../../modules/user/reducers';
import { LanguageType } from '../typings/localization.types';

const UpdateEntities = Actions.entities.updateEntitiesSuccess;

export type UnreadMessageType = {
	[key: string]: number;
};

export type ChatEntityType = 'case' | 'ims';

type EntityPageSetter = EntityPagePayload | ((entityPage: EntityPage) => EntityPagePayload);

type StateContextType = {
	navCollapsed: boolean;
	navToggled: boolean;
	toggleSidebar: () => void;
	collapseSidebar: () => void;
	toggleDark: () => void;
	setPageType: (isScrollable: boolean) => void;
	history: History;
	isDarkMode: boolean;
	hasVScroll: boolean;
	companyId: number | undefined;
	setSelectedBusinessId: (id: number | null) => void;
	setUnreadMessagesCount: (countObject: UnreadMessageType, isAppending?: boolean) => void;
	selectedBusinessId: number | null;
	unreadMessagesCount: null | UnreadMessageType;
	searchQuery: string;
	setSearchQuery: (query: string) => void;
	groupSearchQuery: string;
	setGroupSearchQuery: (query: string) => void;
	registerInfo: RegisterInfo;
	setRegisterInfo: (payload: RegisterInfo) => void;
	chatPanelToggled: boolean;
	toggleChatPanel: (toggleState?: boolean) => void;
	chatUuid: string;
	setChatUuid: (uuid: string) => void;
	appDeviceUuid: string | null;
	setAppDeviceUuid: (uuid: string | null) => void;
	updateEntityField: (
		data: { [key: string]: any },
		entity: string,
		entityId: number | string
	) => void;
	setGroupUnreadMessage: (unreadData: UnreadMessageType) => void;
	setUnreadCasesCountByStatus: (unreadData: UnreadMessageType) => void;
	groupsUnreadCountData: UnreadMessageType;
	unreadCasesCountByStatus: UnreadMessageType;
	activeDraft: EntityDraft;
	setActiveDraft: (draft: EntityDraft) => void;
	setNetworkState: (networkState: 'online' | 'offline') => void;
	networkState: 'online' | 'offline';
	entityPage: EntityPage;
	setEntityPage: (entityPageObj: EntityPageSetter, message?: string) => void;
	hasUnread: HasUnread;
	setHasUnread: (hasUnread: HasUnreadPayload) => void;
	isAdmin: boolean;
	isManager: boolean;
	isPermitted: boolean;
	caseType: CaseType;
	setCaseType: (caseType: CaseType) => void;
	activeFilterTemplate: FilterTemplateType | null;
	setActiveFilterTemplate: (activeFilterTemplate: FilterTemplateType) => void;
	selectedCaseFilter: SelectedCaseFilter;
	setSelectedCaseFilter: (selectedCaseFilter: SelectedCaseFilterPayload) => void;
	areUnreadsFetched: AreUnreadsFetched;
	setAreUnreadsFetched: (areUnreadsFetchedPayload: AreUnreadsFetchedPayload) => void;
	unreadLoadingError: unreadLoadingError;
	setUnreadLoadingError: (error: UnreadLoadingErrorPayload) => void;
	openCaseCreatePopup: OpenCaseCreatePopup;
	showCaseCreatePopup: (isClosing?: boolean | undefined) => void;
	isStaff: boolean;
	selectedStatus: SelectedStatus;
	setSelectedStatus: (status: SelectedStatus) => void;
	statusChangePayload: StatusChangePayload;
	setStatusChangePayload: (payload: StatusChangePayload) => void;
	statusPriorityMessagePopup: StatusPriorityMessagePopupType;
	setStatusPriorityMessagePopup: (payload: StatusPriorityMessagePopupType) => void;
	groupSearchOpen: GroupSearchOpenType;
	setGroupSearchOpen: (payload: GroupSearchOpenType) => void;
	groupMessageSearchQuery: string;
	setGroupMessageSearchQuery: (payload: string) => void;
	setIsLastPageLoadedTriggered: (payload: boolean) => void;
	setIsAppAvailable: (payload: boolean) => void;
	isLastPageLoadedTriggered: boolean;
	isAppAvailable: boolean;
	statusReverseState: StatusReverseState;
	setStatusReverseState: (statusId: number) => void;
	setInitialStatusReverseState: (reverseState: StatusReverseState) => void;
	role: UserRoles;
	settingsData: SettingsDataType;
	setSettingsData: (settings: SettingsDataType) => void;
	isShowMobilePrompt: boolean;
	setIsShowMobilePrompt: (payload: boolean) => void;
	isInternetDisconnected: boolean;
	setIsInternetDisconnected: (payload: boolean) => void;
	funcDecrementUnread: (entityId: number, type: ChatEntityType) => void;
	chatsMentionList: ChatsMentionObject;
	setChatsMentionObject: (payload: ChatsMentionObject) => void;
	scheduledMessageIds: ScheduledMessageIdsObjType;
	setScheduledMessageIds: (payload: ScheduledMessageIdsObjType) => void;
	language: LanguageType;
	setLanguage: (payload: LanguageType) => void;
};
const StateContext = createContext<StateContextType>(null!);

// eslint-disable-next-line @typescript-eslint/ban-types
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
	const [state, dispatch] = useReducer(appReducer, initialState);

	const companyId = useSelector((reduxState: IRootState) => getCompanyId(reduxState));
	const company = useSelector((reduxState: IRootState) => getCompany(reduxState));
	const reduxDispatch = useDispatch();
	const history = useHistory();
	const { children } = props;
	const isAdmin = company?.role.name === 'admin';
	const isManager = company?.role.name === 'manager';
	const isPermitted = isAdmin || isManager;
	const role = company?.role.name as UserRoles;

	const {
		navCollapsed,
		isDarkMode,
		hasVScroll,
		selectedBusinessId,
		navToggled,
		unreadMessagesCount,
		searchQuery,
		registerInfo,
		chatPanelToggled,
		chatUuid,
		appDeviceUuid,
		groupsUnreadCountData,
		groupSearchQuery,
		unreadCasesCountByStatus,
		activeDraft,
		networkState,
		entityPage,
		hasUnread,
		caseType,
		activeFilterTemplate,
		selectedCaseFilter,
		areUnreadsFetched,
		unreadLoadingError,
		openCaseCreatePopup,
		selectedStatus,
		statusChangePayload,
		statusPriorityMessagePopup,
		groupSearchOpen,
		groupMessageSearchQuery,
		isAppAvailable,
		isLastPageLoadedTriggered,
		statusReverseState,
		settingsData,
		isShowMobilePrompt,
		isInternetDisconnected,
		chatsMentionList,
		scheduledMessageIds,
		language
	} = state;

	const isStaff = caseType === config.STAFF;

	useEffect(() => {
		document.documentElement.classList.toggle('dark', isDarkMode);
	}, [isDarkMode]);

	const toggleSidebar = useCallback(() => dispatch({ type: 'TOGGLE_SIDEBAR' }), []);
	const collapseSidebar = useCallback(() => dispatch({ type: 'COLLAPSE_SIDEBAR' }), []);
	const toggleDark = useCallback(() => dispatch({ type: 'TOGGLE_DARK' }), []);

	const setIsShowMobilePrompt = useCallback(
		(isShowing: boolean) => dispatch({ type: 'SET_MOBILE_PROMPT', payload: isShowing }),
		[]
	);

	const setSelectedBusinessId = useCallback((id: number | null) => {
		dispatch({ type: 'SET_SELECTED_BUSINESS_ID', payload: id });
	}, []);
	const setSearchQuery = useCallback((query: string) => {
		dispatch({ type: 'SET_SEARCH_QUERY', payload: query });
	}, []);
	const setGroupSearchQuery = useCallback((query: string) => {
		dispatch({ type: 'SET_GROUP_SEARCH_QUERY', payload: query });
	}, []);
	const setUnreadMessagesCount = useCallback(
		(countObject: UnreadMessageType, isAppending = false) => {
			dispatch({ type: 'SET_UNREAD_MSG_COUNT', payload: { value: countObject, isAppending } });
		},
		[]
	);

	const setRegisterInfo = useCallback((payload: RegisterInfo) => {
		dispatch({ type: 'SET_REGISTER_INFO', payload });
	}, []);
	const toggleChatPanel = useCallback((toggleState?: boolean) => {
		dispatch({ type: 'SET_CHAT_PANEL_TOGGLED', payload: toggleState });
	}, []);

	const setChatUuid = (uuid: string) => dispatch({ type: 'SET_CHAT_UUID', payload: uuid });
	const setAppDeviceUuid = useCallback(
		(uuid: string | null) => dispatch({ type: 'SET_APP_DEVICE_UUID', payload: uuid }),
		[]
	);

	const setPageType = useCallback(
		(isScrollable: boolean) => dispatch({ type: 'SET_PAGE_TYPE', payload: isScrollable }),
		[]
	);

	const setGroupUnreadMessage = useCallback(
		(unreadData: UnreadMessageType) =>
			dispatch({ type: 'SET_GROUP_UNREAD_COUNTS', payload: unreadData }),
		[]
	);

	const setUnreadCasesCountByStatus = useCallback(
		(unreadData: UnreadMessageType) =>
			dispatch({ type: 'SET_UNREAD_CASES_COUNTS', payload: unreadData }),
		[]
	);

	const setActiveDraft = useCallback(
		(draft: EntityDraft) => dispatch({ type: 'SET_ACTIVE_DRAFT', payload: draft }),
		[]
	);

	const setNetworkState = useCallback(
		(networkStateState: 'online' | 'offline') =>
			dispatch({ type: 'SET_NETWORK_STATE', payload: networkStateState }),
		[]
	);

	const updateEntityField = useCallback(
		(data: { [key: string]: any }, entity: string, entityId: number | string) => {
			reduxDispatch(
				UpdateEntities({
					entity,
					entityId: String(entityId),
					data
				})
			);
		},
		[]
	);

	const setEntityPage = useCallback((entityPageObj: EntityPageSetter, message?: string) => {
		if (typeof entityPageObj !== 'function') {
			dispatch({ type: 'SET_ENTITY_PAGE', payload: entityPageObj });
			return;
		}
		dispatch({ type: 'SET_ENTITY_PAGE', payload: entityPageObj(entityPage) });
	}, []);

	const setHasUnread = useCallback(
		(hasUnreadArg: HasUnreadPayload) => dispatch({ type: 'SET_HAS_UNREAD', payload: hasUnreadArg }),
		[]
	);
	const setCaseType = useCallback(
		(caseType: CaseType) => dispatch({ type: 'SET_CASE_TYPE', payload: caseType }),
		[]
	);

	const setActiveFilterTemplate = useCallback(
		(filterTemplate: FilterTemplateType) =>
			dispatch({ type: 'SET_ACTIVE_FILTER_ITEM', payload: filterTemplate }),
		[]
	);
	const setSelectedCaseFilter = useCallback(
		(selectedCaseFilter: SelectedCaseFilterPayload) =>
			dispatch({ type: 'SET_SELECTED_CASE_FILTERS', payload: selectedCaseFilter }),
		[]
	);

	const setAreUnreadsFetched = useCallback(
		(AreUnreadsFetchedPayload: AreUnreadsFetchedPayload) =>
			dispatch({ type: 'ARE_UNREADS_FETCHED', payload: AreUnreadsFetchedPayload }),
		[]
	);

	const setUnreadLoadingError = useCallback(
		(error: UnreadLoadingErrorPayload) =>
			dispatch({ type: 'UNREAD_LOADING_ERROR', payload: error }),
		[]
	);

	const setSelectedStatus = useCallback(
		(status: SelectedStatus) => dispatch({ type: 'SET_SELECTED_STATUS', payload: status }),
		[]
	);

	const showCaseCreatePopup = useCallback(
		(isClosing?: boolean | undefined) => {
			dispatch({
				type: 'SHOW_CASE_CREATE_POPUP',
				payload: isClosing !== undefined ? isClosing : !openCaseCreatePopup
			});
		},
		[openCaseCreatePopup]
	);

	const setStatusChangePayload = useCallback(
		(payload: StatusChangePayload) => dispatch({ type: 'SET_STATUS_CHANGE_PAYLOAD', payload }),
		[]
	);

	const setStatusPriorityMessagePopup = useCallback(
		(payload: StatusPriorityMessagePopupType) =>
			dispatch({ type: 'SET_STATUS_PRIORITY_MESSAGE_POPUP', payload }),
		[]
	);

	const setGroupSearchOpen = useCallback(
		(payload: GroupSearchOpenType) => dispatch({ type: 'SET_GROUP_SEARCH_OPEN', payload }),
		[]
	);
	const setGroupMessageSearchQuery = useCallback(
		(payload: string) => dispatch({ type: 'SET_GROUP_MESSAGE_SEARCH_QUERY', payload }),
		[]
	);
	const setIsLastPageLoadedTriggered = useCallback(
		(payload: boolean) => dispatch({ type: 'SET_IS_LAST_PAGE_LOADED_TRIGGERED', payload }),
		[]
	);

	const setIsAppAvailable = useCallback(
		(payload: boolean) => dispatch({ type: 'SET_IS_APP_AVAILABLE', payload }),
		[]
	);

	const setStatusReverseState = useCallback(
		(statusId: number) => dispatch({ type: 'SET_STATUS_REVERSE_STATE', payload: statusId }),
		[]
	);
	const setInitialStatusReverseState = useCallback(
		(reverseState: StatusReverseState) =>
			dispatch({ type: 'SET_INITIAL_STATUS_REVERSE_STATE', payload: reverseState }),
		[]
	);

	const setSettingsData = useCallback(
		(settingsData: SettingsDataType) =>
			dispatch({ type: 'SET_SETTINGS_DATA', payload: settingsData }),
		[]
	);

	const setIsInternetDisconnected = useCallback(
		(payload: boolean) => dispatch({ type: 'IS_INTERNET_DISCONNECTED', payload }),
		[]
	);

	const setChatsMentionObject = useCallback(
		(payload: ChatsMentionObject) => dispatch({ type: 'CHATS_MENTIONS_LIST', payload }),
		[]
	);

	const setScheduledMessageIds = useCallback(
		(payload: ScheduledMessageIdsObjType) =>
			dispatch({
				type: 'CHATS_SCHEDULED_MESSAGE_LIST',
				payload
			}),
		[]
	);

	const setLanguage = useCallback(
		(payload: LanguageType) =>
			dispatch({
				type: 'SET_LANGUAGE',
				payload
			}),
		[]
	);

	const funcDecrementUnread = useCallback((entityId: number, type: ChatEntityType) => {
		return dispatch((state) => {
			const calculatedUnreadObj =
				type === 'case' ? state.unreadMessagesCount : state.groupsUnreadCountData;

			const calculatedUnreadObjKey: 'unreadMessagesCount' | 'groupsUnreadCountData' =
				type === 'case' ? 'unreadMessagesCount' : 'groupsUnreadCountData';

			return {
				...state,
				[calculatedUnreadObjKey]: {
					...calculatedUnreadObj,
					[entityId]: calculatedUnreadObj?.[entityId] ? calculatedUnreadObj?.[entityId] - 1 : 0
				}
			};
		});
	}, []);

	const contextValues = useMemo(() => {
		return {
			navCollapsed,
			toggleSidebar,
			history,
			isDarkMode,
			toggleDark,
			setPageType,
			hasVScroll,
			companyId,
			selectedBusinessId,
			setSelectedBusinessId,
			collapseSidebar,
			navToggled,
			setUnreadMessagesCount,
			unreadMessagesCount,
			searchQuery,
			setSearchQuery,
			registerInfo,
			setRegisterInfo,
			chatPanelToggled,
			toggleChatPanel,
			setChatUuid,
			chatUuid,
			appDeviceUuid,
			setAppDeviceUuid,
			updateEntityField,
			groupsUnreadCountData,
			setGroupUnreadMessage,
			groupSearchQuery,
			setGroupSearchQuery,
			unreadCasesCountByStatus,
			setUnreadCasesCountByStatus,
			activeDraft,
			setActiveDraft,
			networkState,
			setNetworkState,
			entityPage,
			setEntityPage,
			hasUnread,
			setHasUnread,
			isAdmin,
			isManager,
			isPermitted,
			setCaseType,
			caseType,
			activeFilterTemplate,
			setActiveFilterTemplate,
			selectedCaseFilter,
			setSelectedCaseFilter,
			setAreUnreadsFetched,
			areUnreadsFetched,
			unreadLoadingError,
			setUnreadLoadingError,
			openCaseCreatePopup,
			showCaseCreatePopup,
			isStaff,
			selectedStatus,
			setSelectedStatus,
			statusChangePayload,
			setStatusChangePayload,
			statusPriorityMessagePopup,
			setStatusPriorityMessagePopup,
			groupSearchOpen,
			setGroupSearchOpen,
			groupMessageSearchQuery,
			setGroupMessageSearchQuery,
			setIsLastPageLoadedTriggered,
			isLastPageLoadedTriggered,
			setIsAppAvailable,
			isAppAvailable,
			statusReverseState,
			setStatusReverseState,
			setInitialStatusReverseState,
			role,
			setSettingsData,
			settingsData,
			isShowMobilePrompt,
			setIsShowMobilePrompt,
			funcDecrementUnread,
			isInternetDisconnected,
			setIsInternetDisconnected,
			chatsMentionList,
			setChatsMentionObject,
			scheduledMessageIds,
			setScheduledMessageIds,
			language,
			setLanguage
		};
	}, [
		navCollapsed,
		toggleSidebar,
		history,
		isDarkMode,
		toggleDark,
		setPageType,
		hasVScroll,
		companyId,
		selectedBusinessId,
		setSelectedBusinessId,
		collapseSidebar,
		navToggled,
		setUnreadMessagesCount,
		unreadMessagesCount,
		searchQuery,
		setSearchQuery,
		registerInfo,
		setRegisterInfo,
		chatPanelToggled,
		toggleChatPanel,
		setChatUuid,
		chatUuid,
		appDeviceUuid,
		setAppDeviceUuid,
		updateEntityField,
		groupsUnreadCountData,
		setGroupUnreadMessage,
		groupSearchQuery,
		setGroupSearchQuery,
		unreadCasesCountByStatus,
		setUnreadCasesCountByStatus,
		activeDraft,
		setActiveDraft,
		networkState,
		setNetworkState,
		entityPage,
		setEntityPage,
		hasUnread,
		setHasUnread,
		isAdmin,
		isManager,
		isPermitted,
		setCaseType,
		caseType,
		activeFilterTemplate,
		setActiveFilterTemplate,
		selectedCaseFilter,
		setSelectedCaseFilter,
		setAreUnreadsFetched,
		areUnreadsFetched,
		unreadLoadingError,
		setUnreadLoadingError,
		openCaseCreatePopup,
		showCaseCreatePopup,
		isStaff,
		selectedStatus,
		setSelectedStatus,
		statusChangePayload,
		setStatusChangePayload,
		statusPriorityMessagePopup,
		setStatusPriorityMessagePopup,
		groupSearchOpen,
		setGroupSearchOpen,
		groupMessageSearchQuery,
		setGroupMessageSearchQuery,
		setIsLastPageLoadedTriggered,
		isLastPageLoadedTriggered,
		statusReverseState,
		setStatusReverseState,
		setInitialStatusReverseState,
		setIsAppAvailable,
		isAppAvailable,
		role,
		setSettingsData,
		settingsData,
		isShowMobilePrompt,
		setIsShowMobilePrompt,
		funcDecrementUnread,
		isInternetDisconnected,
		setIsInternetDisconnected,
		chatsMentionList,
		setChatsMentionObject,
		scheduledMessageIds,
		setScheduledMessageIds,
		language,
		setLanguage
	]);

	if (process.env.NODE_ENV !== 'production') {
		console.log('appState', contextValues);
	}

	return <StateContext.Provider value={contextValues}>{children}</StateContext.Provider>;
}

export function useAppState() {
	const context = useContext(StateContext);
	if (!context) throw new Error('useAppState must be used within the AppStateProvider');
	return context;
}
