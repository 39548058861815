import AsyncSelect from '../AsyncSelect';
import { ContactFormSelectStyle } from 'shared/services';
import { ChangeEvent } from 'react';
import { KntInput } from './KntInput';

type Props = {
	type: 'text' | 'email' | 'tel';
	name: string;
	required?: boolean;
	value?: string;
	onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	selectProps: {
		loadOptionsKey: string | ((data: any) => any[]);
		loadOptionsUrl: string;
		loadOptionsParams: any;
		optionLabel: string;
		value: any;
		onChange: (option: any) => void;
		optionValue: (option: any) => void;
	};
};

export const InputWithSelect = (props: Props) => {
	const { type, selectProps, name, required = false, onInputChange, value = '' } = props;
	return (
		<div className="input-with-select flex w-full gap-x-0.5">
			<div className="react-select-dropdown w-3/12">
				{/* @ts-ignore */}
				<AsyncSelect
					customStylesProps={ContactFormSelectStyle}
					customStyles="KntTwilioStyle"
					{...selectProps}
				/>
			</div>

			<KntInput
				name={name}
				required={required}
				type={type}
				value={value}
				onChange={(e) => {
					if (type === 'tel') {
						// Regular expression to allow only digits, spaces, dashes, parentheses, and plus sign
						const cleanedValue = e.target.value.replace(/[^0-9+\-\s()]/g, ''); // Removes any disallowed characters

						// Manually create an event with the cleaned value and pass it to the handler
						const customEvent = {
							...e,
							target: { ...e.target, value: cleanedValue },
							currentTarget: { ...e.currentTarget, value: cleanedValue }
						};

						onInputChange?.(customEvent as ChangeEvent<HTMLInputElement>);
					} else {
						onInputChange?.(e);
					}
				}}
				classNames="w-9/12 mb-1.5"
			/>
		</div>
	);
};
