import { StatusType } from 'pages/Cases/components/Status';
import { UnreadMessageType } from './index';
import { EntityDraft } from '../../pages/GroupChat/types';
import config from '../../config';
import { storage } from 'shared/services';
import { FilterTemplateType } from '../typings';
import { Department } from '../../pages/Cases/cases.types';
import { IUserMessage } from '../interfaces';
import merge from 'lodash/merge';
import { LanguageType } from '../typings/localization.types';

export enum CaseTypes {
	Client = 'Client',
	Staff = 'Staff'
}

export type EntityPage = {
	[key: string]: {
		cursorTop: number | null;
		cursorBot: number | null;
		isLoadedRepliedMessage: boolean;
	};
};

export type EntityPagePayload = {
	entityId: number;
	cursorTop: number | null;
	cursorBot: number | null;
	isLoadedRepliedMessage: boolean;
};

export type CaseExtra = {
	department: string;
	sub_department: string;
	status: string;
	priority: string;
	company_ids?: number;
};

export type RegisterInfo = null | {
	avatar?: null;
	username: string;
	first_name: string;
	last_name: string;
	mobile: string;
	id?: number;
	color?: string;
	password?: string;
};

export type CaseFilterSingle = {
	department: Department[];
	sub_department: Department[];
	status: StatusType[];
	priority: {
		name: string;
		code: string;
	};
	extra: CaseExtra;
	start_date: Date;
	end_date: Date;
};

export type CaseFilterType = {
	[key: string]: CaseFilterSingle;
};

export type HasUnread = {
	staff: boolean | null;
	client: boolean | null;
};

export type CaseType = CaseTypes.Client | CaseTypes.Staff;

export type SelectedCaseFilter = {
	[CaseTypes.Client]: FilterTemplateType | null;
	[CaseTypes.Staff]: FilterTemplateType | null;
};

export type AreUnreadsFetched = {
	client: boolean;
	staff: boolean;
	group: boolean;
	all: boolean;
};

export type OpenCaseCreatePopup = boolean;

export type unreadLoadingError = null | string;

export type SelectedStatus = {
	name: string;
	statusId: number;
	type: 'edit' | 'delete' | 'mark_all_read' | 'add_buttons';
} | null;

export type StatusChangePayload = {
	prevStatus: number;
	sourceIndex: number;
	entityId: number;
	searchEntityId: number;
} | null;

export type StatusPriorityMessagePopupType = {
	from_user: IUserMessage;
	to_user: IUserMessage | null;
	actualType: string;
	sent_time: number;
	text: string;
	type: 'case' | 'group';
} | null;

export type GroupSearchOpenType = boolean;

export type StatusReverseState = {
	[key: string]: boolean;
} | null;

export type SettingsDataType = {
	id: number;
	user_id: number;
	company_id: number;
	settings_json: { [key: string]: any };
} | null;

export type ChatsMentionObject = Record<string, number[]>;

export type ScheduledMessageIdsObjType = Record<string, number[]>;

export type AppStateType = {
	navCollapsed: boolean;
	isDarkMode: boolean;
	hasVScroll: boolean;
	selectedBusinessId: null | number;
	navToggled: boolean;
	searchQuery: string;
	groupSearchQuery: string;
	caseFilter: CaseFilterType;
	registerInfo: RegisterInfo;
	chatPanelToggled: boolean;
	chatUuid: string;
	appDeviceUuid: string | null;
	unreadMessagesCount: null | UnreadMessageType;
	groupsUnreadCountData: UnreadMessageType;
	unreadCasesCountByStatus: UnreadMessageType;
	activeDraft: EntityDraft;
	networkState: 'online' | 'offline';
	entityPage: EntityPage;

	hasUnread: HasUnread;
	caseType: CaseType;
	activeFilterTemplate: FilterTemplateType | null;
	selectedCaseFilter: SelectedCaseFilter;
	areUnreadsFetched: AreUnreadsFetched;
	unreadLoadingError: unreadLoadingError;
	openCaseCreatePopup: OpenCaseCreatePopup;
	selectedStatus: SelectedStatus;
	statusChangePayload: StatusChangePayload;
	statusPriorityMessagePopup: StatusPriorityMessagePopupType;
	groupSearchOpen: GroupSearchOpenType;
	groupMessageSearchQuery: string;
	isLastPageLoadedTriggered: boolean;
	isAppAvailable: boolean;
	statusReverseState: StatusReverseState;
	settingsData: SettingsDataType;
	isShowMobilePrompt: boolean;
	isInternetDisconnected: boolean;
	chatsMentionList: ChatsMentionObject;
	scheduledMessageIds: ScheduledMessageIdsObjType;
	language: LanguageType;
};

export const initialState: AppStateType = {
	navCollapsed: false,
	language: JSON.parse(storage.get('selected_language') as string),
	isDarkMode: storage.get('darkMode') ? storage.get('darkMode') === 'true' : false,
	hasVScroll: false,
	selectedBusinessId: null,
	navToggled: false,
	unreadMessagesCount: null,
	searchQuery: '',
	groupSearchQuery: '',
	caseFilter: {},
	registerInfo: null,
	chatPanelToggled: false,
	chatUuid: 'initialString',
	appDeviceUuid: null,
	groupsUnreadCountData: {},
	unreadCasesCountByStatus: {},
	activeDraft: null,
	networkState: 'online',
	entityPage: {
		initial: {
			cursorTop: 1,
			cursorBot: 1,
			isLoadedRepliedMessage: false
		}
	},
	hasUnread: {
		client: null,
		staff: null
	},
	caseType: (storage.get('caseType') as CaseType) || (config.STAFF as CaseType), //default caseType is STAFF
	activeFilterTemplate: null,
	selectedCaseFilter: {
		[CaseTypes.Client]: null,
		[CaseTypes.Staff]: null
	},
	areUnreadsFetched: {
		staff: false,
		client: false,
		group: false,
		all: false
	},
	unreadLoadingError: null,
	openCaseCreatePopup: false,
	selectedStatus: null,
	statusChangePayload: null,
	statusPriorityMessagePopup: null,
	groupSearchOpen: false,
	groupMessageSearchQuery: '',
	isLastPageLoadedTriggered: false,
	isAppAvailable: false,
	statusReverseState: null,
	settingsData: null,
	isShowMobilePrompt: false,
	isInternetDisconnected: false,
	chatsMentionList: {},
	scheduledMessageIds: {}
};

export type SetLanguagePayloadType = {
	type: 'SET_LANGUAGE';
	payload: LanguageType;
};

type toggleSidebar = {
	type: 'TOGGLE_SIDEBAR';
};

type collapseSidebar = {
	type: 'COLLAPSE_SIDEBAR';
};

type toggleDarkMode = {
	type: 'TOGGLE_DARK';
};

type selectedBusinessId = {
	type: 'SET_SELECTED_BUSINESS_ID';
	payload: number | null;
};

type setPageType = {
	type: 'SET_PAGE_TYPE';
	payload: boolean;
};

type setUnreadCount = {
	type: 'SET_UNREAD_MSG_COUNT';
	payload: {
		value: UnreadMessageType;
		isAppending: boolean;
	};
};

type setSearchQuery = {
	type: 'SET_SEARCH_QUERY';
	payload: string;
};

type setGroupSearchQuery = {
	type: 'SET_GROUP_SEARCH_QUERY';
	payload: string;
};

type setRegisterInfo = {
	type: 'SET_REGISTER_INFO';
	payload: RegisterInfo;
};
type setChatPanelToggled = {
	type: 'SET_CHAT_PANEL_TOGGLED';
	payload?: boolean;
};

type setChatUuid = {
	type: 'SET_CHAT_UUID';
	payload: string;
};
type setAppDeviceUuid = {
	type: 'SET_APP_DEVICE_UUID';
	payload: string | null;
};

export type SetGroupUnreadMessagesCount = {
	type: 'SET_GROUP_UNREAD_COUNTS';
	payload: UnreadMessageType;
};

export type SetUnreadCasesCount = {
	type: 'SET_UNREAD_CASES_COUNTS';
	payload: UnreadMessageType;
};

export type SetActiveDraft = {
	type: 'SET_ACTIVE_DRAFT';
	payload: EntityDraft;
};

export type SetEntityPage = {
	type: 'SET_ENTITY_PAGE';
	payload: EntityPagePayload;
};

type SetNetworkState = {
	type: 'SET_NETWORK_STATE';
	payload: 'online' | 'offline';
};

export type HasUnreadPayload = {
	type: 'client' | 'staff';
	value: boolean;
};

export type SetHasUnread = {
	type: 'SET_HAS_UNREAD';
	payload: HasUnreadPayload;
};

export type SetCaseType = {
	type: 'SET_CASE_TYPE';
	payload: CaseType;
};

export type SetActiveFilterTemplate = {
	type: 'SET_ACTIVE_FILTER_ITEM';
	payload: FilterTemplateType;
};

export type SelectedCaseFilterPayload = {
	filter: SelectedCaseFilter;
	caseType: CaseTypes.Client | CaseTypes.Staff | null;
	isInitialDispatch: boolean;
};

export type SetSelectedCaseFilter = {
	type: 'SET_SELECTED_CASE_FILTERS';
	payload: SelectedCaseFilterPayload;
};

export type unreadTypes = 'staff' | 'client' | 'group' | 'all';
export type AreUnreadsFetchedPayload = {
	type: unreadTypes;
	value: boolean;
};

export type SetAreUnreadsFetched = {
	type: 'ARE_UNREADS_FETCHED';
	payload: AreUnreadsFetchedPayload;
};

export type UnreadLoadingErrorPayload = string | null;

export type SetUnreadLoadingError = {
	type: 'UNREAD_LOADING_ERROR';
	payload: UnreadLoadingErrorPayload;
};

export type ShowCaseCreatePopup = {
	type: 'SHOW_CASE_CREATE_POPUP';
	payload: boolean;
};
export type SetSelectedStatus = {
	type: 'SET_SELECTED_STATUS';
	payload: SelectedStatus;
};

export type SetStatusChangePayload = {
	type: 'SET_STATUS_CHANGE_PAYLOAD';
	payload: StatusChangePayload;
};

export type SetStatusPriorityMessagePopup = {
	type: 'SET_STATUS_PRIORITY_MESSAGE_POPUP';
	payload: StatusPriorityMessagePopupType;
};

export type SetGroupSearchOpen = {
	type: 'SET_GROUP_SEARCH_OPEN';
	payload: GroupSearchOpenType;
};

export type SetGroupMessageSearchQuery = {
	type: 'SET_GROUP_MESSAGE_SEARCH_QUERY';
	payload: string;
};

export type SetIsLastPageLoadedTriggered = {
	type: 'SET_IS_LAST_PAGE_LOADED_TRIGGERED';
	payload: boolean;
};

export type SetIsAppAvailable = {
	type: 'SET_IS_APP_AVAILABLE';
	payload: boolean;
};

export type SetStatusReverseState = {
	type: 'SET_STATUS_REVERSE_STATE';
	payload: number;
};
export type SetInitialStatusReverseState = {
	type: 'SET_INITIAL_STATUS_REVERSE_STATE';
	payload: StatusReverseState;
};

export type SetSettingsData = {
	type: 'SET_SETTINGS_DATA';
	payload: SettingsDataType;
};

export type SetMobilePromptState = {
	type: 'SET_MOBILE_PROMPT';
	payload: boolean;
};

export type SetIsInternetDisconnected = {
	type: 'IS_INTERNET_DISCONNECTED';
	payload: boolean;
};

export type SetCasesMentionList = {
	type: 'CHATS_MENTIONS_LIST';
	payload: ChatsMentionObject;
};

export type SetChatsScheduledMessageList = {
	type: 'CHATS_SCHEDULED_MESSAGE_LIST';
	payload: ScheduledMessageIdsObjType;
};

export type objectTypes =
	| toggleSidebar
	| toggleDarkMode
	| setPageType
	| selectedBusinessId
	| collapseSidebar
	| setUnreadCount
	| setSearchQuery
	| setRegisterInfo
	| setChatPanelToggled
	| setChatUuid
	| setAppDeviceUuid
	| SetGroupUnreadMessagesCount
	| setGroupSearchQuery
	| SetUnreadCasesCount
	| SetActiveDraft
	| SetNetworkState
	| SetEntityPage
	| SetHasUnread
	| SetCaseType
	| SetActiveFilterTemplate
	| SetSelectedCaseFilter
	| SetAreUnreadsFetched
	| SetUnreadLoadingError
	| ShowCaseCreatePopup
	| SetSelectedStatus
	| SetStatusChangePayload
	| SetStatusPriorityMessagePopup
	| SetGroupSearchOpen
	| SetGroupMessageSearchQuery
	| SetStatusReverseState
	| SetInitialStatusReverseState
	| SetIsLastPageLoadedTriggered
	| SetIsAppAvailable
	| SetSettingsData
	| SetMobilePromptState
	| SetIsInternetDisconnected
	| SetCasesMentionList
	| SetChatsScheduledMessageList
	| SetLanguagePayloadType;

export type actionType = objectTypes | ((prevState: AppStateType) => AppStateType);

export function appReducer(state: AppStateType, action: actionType): AppStateType {
	if (typeof action === 'function') {
		return action(state);
	} else {
		switch (action.type) {
			case 'SET_LANGUAGE': {
				return {
					...state,
					language: action.payload
				};
			}
			case 'TOGGLE_SIDEBAR': {
				return {
					...state,
					navToggled: !state.navToggled
				};
			}
			case 'COLLAPSE_SIDEBAR': {
				return {
					...state,
					navCollapsed: !state.navCollapsed
				};
			}
			case 'TOGGLE_DARK': {
				return {
					...state,
					isDarkMode: !state.isDarkMode
				};
			}
			case 'SET_PAGE_TYPE': {
				return {
					...state,
					hasVScroll: action.payload
				};
			}
			case 'SET_SELECTED_BUSINESS_ID': {
				return {
					...state,
					selectedBusinessId: action.payload
				};
			}
			case 'SET_UNREAD_MSG_COUNT': {
				return {
					...state,
					unreadMessagesCount: !state.unreadMessagesCount
						? action.payload.value
						: action.payload.isAppending
						? { ...state.unreadMessagesCount, ...action.payload.value }
						: action.payload.value
				};
			}
			case 'SET_SEARCH_QUERY': {
				return {
					...state,
					searchQuery: action.payload
				};
			}
			case 'SET_GROUP_SEARCH_QUERY': {
				return {
					...state,
					groupSearchQuery: action.payload
				};
			}
			case 'SET_REGISTER_INFO': {
				return {
					...state,
					registerInfo: action.payload
				};
			}
			case 'SET_CHAT_PANEL_TOGGLED': {
				return {
					...state,
					chatPanelToggled: action.payload !== undefined ? action.payload : !state.chatPanelToggled
				};
			}
			case 'SET_CHAT_UUID': {
				return {
					...state,
					chatUuid: action.payload
				};
			}
			case 'SET_APP_DEVICE_UUID': {
				return {
					...state,
					appDeviceUuid: action.payload
				};
			}
			case 'SET_GROUP_UNREAD_COUNTS': {
				return {
					...state,
					groupsUnreadCountData: action.payload
				};
			}
			case 'SET_UNREAD_CASES_COUNTS': {
				return {
					...state,
					unreadCasesCountByStatus: action.payload
				};
			}
			case 'SET_ACTIVE_DRAFT': {
				return {
					...state,
					activeDraft: action.payload
				};
			}
			case 'SET_NETWORK_STATE': {
				return {
					...state,
					networkState: action.payload
				};
			}
			case 'SET_HAS_UNREAD': {
				return {
					...state,
					hasUnread: { ...state.hasUnread, [action.payload.type]: action.payload.value }
				};
			}
			case 'SET_CASE_TYPE': {
				return {
					...state,
					caseType: action.payload
				};
			}
			case 'SET_ENTITY_PAGE':
				return {
					...state,
					entityPage: {
						...state.entityPage,
						[String(action.payload.entityId)]: {
							cursorTop: action.payload.cursorTop,
							cursorBot: action.payload.cursorBot,
							isLoadedRepliedMessage: action.payload.isLoadedRepliedMessage
						}
					}
				};
			case 'SET_ACTIVE_FILTER_ITEM': {
				return {
					...state,
					activeFilterTemplate: action.payload
				};
			}
			case 'SET_SELECTED_CASE_FILTERS': {
				return {
					...state,
					selectedCaseFilter: action.payload.isInitialDispatch
						? action.payload.filter
						: {
								...state.selectedCaseFilter,
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								[action.payload.caseType!]: action.payload.filter[action.payload.caseType!]
						  }
				};
			}
			case 'ARE_UNREADS_FETCHED': {
				return {
					...state,
					areUnreadsFetched: {
						...state.areUnreadsFetched,
						[action.payload.type]: action.payload.value
					}
				};
			}
			case 'UNREAD_LOADING_ERROR': {
				return {
					...state,
					unreadLoadingError: action.payload
				};
			}
			case 'SHOW_CASE_CREATE_POPUP': {
				return {
					...state,
					openCaseCreatePopup: action.payload
				};
			}
			case 'SET_SELECTED_STATUS': {
				return {
					...state,
					selectedStatus: action.payload
				};
			}
			case 'SET_STATUS_CHANGE_PAYLOAD': {
				return {
					...state,
					statusChangePayload: action.payload
				};
			}
			case 'SET_STATUS_PRIORITY_MESSAGE_POPUP': {
				return {
					...state,
					statusPriorityMessagePopup: action.payload
				};
			}
			case 'SET_GROUP_SEARCH_OPEN': {
				return {
					...state,
					groupSearchOpen: action.payload
				};
			}
			case 'SET_GROUP_MESSAGE_SEARCH_QUERY': {
				return {
					...state,
					groupMessageSearchQuery: action.payload
				};
			}
			case 'SET_IS_LAST_PAGE_LOADED_TRIGGERED': {
				return {
					...state,
					isLastPageLoadedTriggered: action.payload
				};
			}
			case 'SET_IS_APP_AVAILABLE': {
				return {
					...state,
					isAppAvailable: action.payload
				};
			}
			case 'SET_STATUS_REVERSE_STATE': {
				return {
					...state,
					statusReverseState: {
						...state.statusReverseState,
						[action.payload]: !state.statusReverseState?.[action.payload]
					}
				};
			}

			case 'SET_INITIAL_STATUS_REVERSE_STATE': {
				return {
					...state,
					statusReverseState: action.payload
				};
			}
			case 'SET_SETTINGS_DATA': {
				return {
					...state,
					settingsData: state.settingsData
						? merge({}, state.settingsData, action.payload)
						: action.payload
				};
			}

			case 'SET_MOBILE_PROMPT': {
				return {
					...state,
					isShowMobilePrompt: action.payload
				};
			}

			case 'IS_INTERNET_DISCONNECTED': {
				return {
					...state,
					isInternetDisconnected: action.payload
				};
			}
			case 'CHATS_MENTIONS_LIST': {
				return {
					...state,
					chatsMentionList: action.payload
				};
			}

			case 'CHATS_SCHEDULED_MESSAGE_LIST': {
				return {
					...state,
					scheduledMessageIds: action.payload
				};
			}

			default:
				return state;
		}
	}
}
