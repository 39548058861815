import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Loader } from 'shared/components';
import { MainLayout } from 'shared/layouts';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useAppState } from 'shared/state';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import UserSocketProvider from 'shared/userSocket';
import MessageIcon from '@material-ui/icons/Message';
import App from './App';
import { useQueryParams, useRouter } from './shared/hooks';
import FeedbackPage from './pages/FeedbackPage';

const Home = lazy(() => import('./pages/Home'));
// const List = lazy(() => import('./pages/List'));
const Logout = lazy(() => import('./pages/Logout'));
const LoginPage = lazy(() => import('./pages/Login'));
const SignUpPage = lazy(() => import('./pages/SignUp'));
const CasesPage = lazy(() => import('./pages/Cases'));
const GroupChatPage = lazy(() => import('./pages/GroupChat'));
const Welcome = lazy(() => import('./pages/Welcome'));
const BusinessManagement = lazy(() => import('./pages/BusinessManagement'));
const RequestPassword = lazy(() => import('./pages/RequestPassword'));
const ChangePassword = lazy(() => import('./pages/ChangePassword'));
const UserProfile = lazy(() => import('./pages/UserProfile'));
const PreloaderPage = lazy(() => import('./pages/PreloaderPage'));
const ActivatePage = lazy(() => import('./pages/ActivatePage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const SetPassword = lazy(() => import('./pages/SetPassword'));
const AuthSetPassword = lazy(() => import('./pages/AuthSetPassword'));
const AuthEmailConfirmation = lazy(() => import('./pages/AuthEmailConfirmation'));
const AuthTransferOwner = lazy(() => import('./pages/AuthTransferOwner'));
const AddBusinessPage = lazy(() => import('./pages/AddBusiness'));
const PendingPage = lazy(() => import('./pages/PendingPage'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const BusinessProfileMainPage = lazy(() => import('./pages/BusinessProfileMain'));
const InitialLoaderPage = lazy(() => import('./pages/InitialLoaderPage'));
const EmailConfirmationPage = lazy(() => import('./pages/EmailConfirmation'));
const DepartmentsPage = lazy(() => import('./pages/Departments'));
const DepartmentDetails = lazy(() => import('./pages/Departments/components/DepartmentDetails'));
const StaffManagement = lazy(() => import('./pages/StaffManagement'));
const StaffManagementDetails = lazy(
	() => import('./pages/StaffManagement/components/staffDetails')
);
const ClientsManagement = lazy(() => import('./pages/ClientsManagement'));
const News = lazy(() => import('./pages/News'));
const Csv = lazy(() => import('./pages/Csv'));
const PaymentsMain = lazy(() => import('./pages/Payments'));
const Checkout = lazy(() => import('./pages/Checkout'));
const PaymentSuccess = lazy(() => import('./pages/PaymentSuccess'));
const PaymentFailed = lazy(() => import('./pages/PaymentFailed'));
const OnHold = lazy(() => import('./pages/OnHold'));
const AllCases = lazy(() => import('./pages/AllCases'));
const KennekSupprt = lazy(() => import('./pages/KennektSupport'));
const TestErrorPage = lazy(() => import('./pages/TestErrorPage'));
const ChangePasswordRedirection = lazy(
	() => import('./pages/ChangePassword/ChangePasswordRedirection')
);
const ContactsPage = lazy(() => import('./pages/Contacts'));

const routes = [
	{
		path: '/app/set-password/:code',
		exact: true,
		component: AuthSetPassword,
		isAvailableInNoPayment: false,
		adminOnlyAccess: false
	},
	{
		path: '/case-messages',
		exact: true,
		component: CasesPage,
		isAvailableInNoPayment: false,
		adminOnlyAccess: false
	},
	{
		path: '/group-chat',
		exact: true,
		component: GroupChatPage,
		isAvailableInNoPayment: false,
		adminOnlyAccess: false
	},
	{
		path: '/logout',
		exact: true,
		component: Logout,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '/switch-business',
		component: BusinessManagement,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '/',
		exact: true,
		component: Home,
		isAvailableInNoPayment: false,
		adminOnlyAccess: false
	},
	{
		path: '/user-profile',
		exact: true,
		component: UserProfile,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '/business-profile',
		component: BusinessProfileMainPage,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '/preloader',
		exact: true,
		component: PreloaderPage,
		isAvailableInNoPayment: false,
		adminOnlyAccess: false
	},
	{
		path: '/add-business',
		exact: true,
		component: AddBusinessPage,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '/pending-page',
		exact: true,
		component: PendingPage,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '/dashboard',
		exact: true,
		component: Dashboard,
		isAvailableInNoPayment: false,
		adminOnlyAccess: false
	},
	{
		path: '/initial-loader',
		exact: true,
		component: InitialLoaderPage,
		isAvailableInNoPayment: false,
		adminOnlyAccess: false
	},
	{
		path: '/app/sts/:code',
		exact: true,
		component: AuthEmailConfirmation,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '/app/transfer/sts/:code',
		exact: true,
		component: AuthTransferOwner,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '/sts/:code',
		exact: true,
		component: ActivatePage,
		isAvailableInNoPayment: false,
		adminOnlyAccess: false
	},
	{ path: '/news', component: News, isAvailableInNoPayment: false, adminOnlyAccess: false },
	{
		path: '/clients-management',
		component: ClientsManagement,
		isAvailableInNoPayment: false,
		adminOnlyAccess: false
	},
	{
		path: '/departments/view/:id/:dep?/',
		component: DepartmentDetails,
		isAvailableInNoPayment: false,
		adminOnlyAccess: false
	},
	{
		path: '/departments',
		component: DepartmentsPage,
		isAvailableInNoPayment: false,
		adminOnlyAccess: false
	},
	{
		path: '/staffs-management',
		exact: true,
		component: StaffManagement,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '/staffs-management/view/:id',
		component: StaffManagementDetails,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{ path: '/csv', component: Csv, isAvailableInNoPayment: false, adminOnlyAccess: false },
	{
		path: '/payments',
		component: PaymentsMain,
		isAvailableInNoPayment: true,
		adminOnlyAccess: true
	},
	{
		path: '/checkout/:invoice?/:isMonthly?/:staffsCount?',
		component: Checkout,
		isAvailableInNoPayment: true,
		adminOnlyAccess: true
	},
	{
		path: '/payment-success',
		component: PaymentSuccess,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '/payment-failed',
		component: PaymentFailed,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '/payment-waiting',
		component: OnHold,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{ path: '/all-cases', component: AllCases, isAvailableInNoPayment: true, adminOnlyAccess: false },
	{
		path: '/support',
		component: KennekSupprt,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '/change-password',
		component: ChangePasswordRedirection,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '/contacts',
		component: ContactsPage,
		isAvailableInNoPayment: false,
		adminOnlyAccess: false
	},
	{
		path: '/test-sentry',
		component: TestErrorPage,
		isAvailableInNoPayment: true,
		adminOnlyAccess: false
	},
	{
		path: '',
		exact: true,
		component: NotFoundPage,
		isAvailableInNoPayment: false,
		adminOnlyAccess: false
	}
];

function Root() {
	const { isAuthentificated, isFetched, companies } = useAuth();
	const { isDarkMode, companyId, setIsAppAvailable, isAppAvailable, isPermitted } = useAppState();
	const { history } = useRouter();
	const { query } = useQueryParams();

	const isNewUserRegistration = query?.is_registering_new;
	const setPassWordIsActive = query?.username;

	// Check whether business has payment available
	useEffect(() => {
		if (companyId && companies.length) {
			const currentCompany = companies.find((com) => com?.company?.id === companyId);
			const subscription = currentCompany?.company.comp_subscription;

			if (subscription?.is_available) {
				setIsAppAvailable(true);
			} else if (subscription && !subscription.is_available) {
				history.push('/payment-waiting');
			}
		}
	}, [companies, companyId, setIsAppAvailable]);

	const theme = createMuiTheme({
		typography: {
			fontFamily: '"Poppins", cursive'
		},
		// overrides: {
		// 	MuiCssBaseline: {
		// 		'@global': {
		// 			'@font-face': [yellowtail],
		// 		}
		// 	}
		// },
		palette: {
			type: isDarkMode ? 'dark' : 'light',
			primary: {
				main: '#00D0BD'
			}
		}
	});

	return (
		<App>
			<ThemeProvider theme={theme}>
				<SnackbarProvider
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
					className="snackbar-wrapper"
					iconVariant={{
						success: <MessageIcon style={{ marginRight: '10px' }} />
					}}
				>
					<UserSocketProvider>
						{isFetched ? (
							<Switch>
								<Route path={'/feedback/:companyUniqId'} exact component={FeedbackPage} />
								{isAuthentificated ? (
									<MainLayout>
										<Suspense fallback={<Loader tiled />}>
											<Switch>
												{routes
													.filter((route) =>
														isAppAvailable ? route : route.isAvailableInNoPayment
													)
													.filter((route) => (isPermitted ? route : !route.adminOnlyAccess))
													.map((route, key) => (
														<Route
															key={key}
															path={route.path}
															component={route.component}
															exact={route.exact}
														/>
													))}
											</Switch>
										</Suspense>
									</MainLayout>
								) : (
									<Suspense fallback={<Loader tiled />}>
										<Switch>
											<Route path="/" component={Welcome} exact />
											<Route path="/initial-loader" component={InitialLoaderPage} exact />
											<Route path="/signin" component={LoginPage} exact />
											<Route path="/signup" component={SignUpPage} exact />
											<Route path="/request-password" component={RequestPassword} exact />
											<Route path="/app/set-password/:code" component={SetPassword} exact />
											<Route path="/set-password/:code" component={SetPassword} exact />
											<Route path="/change-password" component={ChangePassword} exact />
											<Route path="/sts/:code" component={ActivatePage} exact />
											<Route
												path="/app/sts/:code"
												component={!!isNewUserRegistration ? EmailConfirmationPage : ActivatePage}
												exact
											/>
											<Route path="/app/transfer/sts/:code" component={ActivatePage} exact />
											<Redirect from="*" to={!!setPassWordIsActive ? window.location.href : '/'} />
										</Switch>
									</Suspense>
								)}
							</Switch>
						) : (
							<Loader full />
						)}
					</UserSocketProvider>
				</SnackbarProvider>
			</ThemeProvider>
		</App>
	);
}
export default Root;
