import { useAppState } from 'shared/state';
import { useCallback, useEffect, useState } from 'react';
import { api, queryBuilder } from 'shared/services';

export function useCDAssigneeEdit({
																		caseId,
																		members,
																		setFieldValue,
																		setAssignedUsers,
																		assignedUsers
																	}: any) {
	const { companyId } = useAppState();
	const [assigneeTypeValue, setAssigneeTypeValue] = useState('all');
	//todo: refactor to remove this code
	// const [localAssigneeUsers, setLocalAssigneeUsers] = useState<any>([]);

	useEffect(() => {
		setFieldValue('assignee_ids', assignedUsers);
	}, [assignedUsers]);

	const loadAssigneeUsers = useCallback(async () => {
		const { data } = await api.request.get(
			queryBuilder(`/${companyId}/cases/as_business/${caseId}/assigned_users/`, {})
		);

		//setAssignedUsers
		if (Array.isArray(data)) {
			setAssignedUsers(data);

			//Set local Assignee users (We need this, because setAssignedUsers function is not working correctly and it is not setting loaded assigneed users)
			// setLocalAssigneeUsers(data);
		} else {
			setAssignedUsers([]);
		}
	}, [caseId, companyId]);

	//Fetch assigned users
	useEffect(() => {
		(async function () {
			try {
				if(!assignedUsers) {
					await loadAssigneeUsers();
				}
			} catch (err) {
				console.error(err);
			}
		})();
	}, [assignedUsers]);

	//todo: remove commented code if it is unnecessary
	// set assignee users (We need this, because setAssignedUsers function is not working correctly and it is not setting loaded assigneed users)
	// useEffect(() => {
	// 	if (localAssigneeUsers.length && !assignedUsers.length) {
	// 		setAssignedUsers(localAssigneeUsers);
	// 	}
	// }, [localAssigneeUsers, assignedUsers]);

	//Assignee type radio buttons change handler
	const handleAssigneeTypeChange = useCallback((event) => {
		setAssigneeTypeValue(event.target.value);
	}, []);

	const removeMemberFromAssigneeList = (members: number[]) => {
		const filteredAssigneeUsers = assignedUsers.filter((member: any) =>
			members.includes(member?.user ? member.user?.id : member.id)
		);
		setAssignedUsers(filteredAssigneeUsers);
		// setLocalAssigneeUsers(filteredAssigneeUsers);
	};

	useEffect(() => {
		if (members && members.length) {
			const membersIds = members.map((member: any) =>
				member?.user ? member?.user?.id : member?.id
			);

			removeMemberFromAssigneeList(membersIds);
		}
	}, [members]);

	const handleAssignedMember = (option: any) => {
		setAssignedUsers(option);
		// setLocalAssigneeUsers(option);
	};

	return {
		assigneeTypeValue,
		handleAssigneeTypeChange,
		handleAssignedMember
	};
}
