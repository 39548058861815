import { Case } from '../../interfaces';
import { ChatContextActionNames } from '../types/chatContextTypes';
// import { EntityDraft } from 'pages/GroupChat/types';

export type Direction = string | null;

export type IncomingTyping = {
	entity_id: number;
	entity_type: 'case' | 'ims';
	type: 'typing';
	user_id: string;
	user: {
		first_name: string;
		last_name: string;
		id: number;
	};
};

export type ChatSocketState = 'Connecting' | 'Open' | 'Closing' | 'Closed' | 'Uninstantiated';

type Avatar = {
	thumbnail: string;
};

export type MentionUserEntity = {
	id: number;
	first_name: string;
	last_name: string;
	display_name: string;
	avatar: Avatar;
	is_staff: boolean;
};

export type ChatStateType = {
	allowToLoad: boolean;
	direction: Direction;
	isSetSearchReply: boolean;
	replyLoading: boolean;
	scrollToMsgId: null | number;
	activeEntity: Case | null;
	isInBottom: boolean;
	socketState: string;
	typingUsers: IncomingTyping[];
	isMsgHasBeenIndicated: boolean;
	isLastPageFetched: boolean;
	isFirstPageFetched: boolean;
	lastReadMessageId: number | null;
	mentionUsersList: MentionUserEntity[];
	isBottomLoading: boolean;
	isChatHandshakeProcessed:boolean;
};

export const initialChatState = {
	allowToLoad: false,
	direction: null,
	isSetSearchReply: false,
	replyLoading: false,
	scrollToMsgId: null,
	activeEntity: null,
	isInBottom: false,
	socketState: 'Uninstantiated',
	typingUsers: [],
	isMsgHasBeenIndicated: false,
	isLastPageFetched: false,
	isFirstPageFetched: false,
	groupSearchOpen: false,
	lastReadMessageId: null,
	mentionUsersList: [],
	isBottomLoading: false,
	isChatHandshakeProcessed: false
};

export type SetBottomLoading = {
	type: ChatContextActionNames.SET_BOTTOM_LOADING;
	payload: boolean;
};

export type AllowLoad = {
	type: 'ALLOW_LOAD';
	payload: boolean;
};

export type SetDirection = {
	type: 'SET_DIRECTION';
	payload: Direction;
};

export type SetSearchReply = {
	type: 'SET_SEARCH_REPLY';
	payload: boolean;
};
export type SetReplyLoading = {
	type: 'SET_REPLY_LOADING';
	payload: boolean;
};

export type SetScrollToMsgId = {
	type: 'SET_SCROLL_TO_MSG_ID';
	payload: number | null;
};

export type SetIsMessagesScrollInBottom = {
	type: 'SET_IS_SCROLL_IN_BOTTOM';
	payload: boolean;
};

export type SetChatSocketState = {
	type: 'SET_CHAT_SOCKET_STATE';
	payload: string;
};

export type SetTypingUsers = {
	type: 'SET_TYPING_USERS';
	payload: IncomingTyping;
};
export type UnSetTypingUsers = {
	type: 'UNSET_TYPING_USERS';
	payload: IncomingTyping;
};
export type SetIsMsgHasBeenIndicated = {
	type: 'SET_MSG_HAS_BEEN_INDICATED';
	payload: boolean;
};

export type SetIsLastPageFetched = {
	type: 'SET_IS_LAST_PAST_FETCHED';
	payload: boolean;
};

export type SetIsFirstPageFetched = {
	type: 'SET_IS_FIRST_PAST_FETCHED';
	payload: boolean;
};
export type SetLastReadMessageId = {
	type: 'SET_LAST_READ_MESSAGE_ID';
	payload: number | null;
};

export type SetMentionUsersList = {
	type: 'SET_MENTION_USERS_LIST';
	payload: MentionUserEntity[];
};

export type SetIsChatHandshakeProcessed = {
	type: 'IS_HANDSHAKE_PROCESSED';
	payload: boolean;
};

type ChatActions =
	| AllowLoad
	| SetDirection
	| SetSearchReply
	| SetReplyLoading
	| SetScrollToMsgId
	| SetIsMessagesScrollInBottom
	| SetChatSocketState
	| SetTypingUsers
	| UnSetTypingUsers
	| SetIsMsgHasBeenIndicated
	| SetIsLastPageFetched
	| SetIsFirstPageFetched
	| SetLastReadMessageId
	| SetMentionUsersList
	| SetBottomLoading | SetIsChatHandshakeProcessed;

export const chatContextReducer = (state: ChatStateType, action: ChatActions): ChatStateType => {
	switch (action.type) {
		case ChatContextActionNames.SET_BOTTOM_LOADING: {
			return {
				...state,
				isBottomLoading: action.payload
			};
		}
		case 'ALLOW_LOAD': {
			return { ...state, allowToLoad: action.payload };
		}
		case 'SET_DIRECTION':
			return { ...state, direction: action.payload };
		case 'SET_SEARCH_REPLY':
			return {
				...state,
				isSetSearchReply: action.payload
			};
		case 'SET_REPLY_LOADING':
			return {
				...state,
				replyLoading: action.payload
			};
		case 'SET_SCROLL_TO_MSG_ID':
			return {
				...state,
				scrollToMsgId: action.payload
			};
		case 'SET_IS_SCROLL_IN_BOTTOM':
			return {
				...state,
				isInBottom: action.payload
			};
		case 'SET_CHAT_SOCKET_STATE':
			return {
				...state,
				socketState: action.payload
			};
		case 'SET_TYPING_USERS': {
			const filteredUsers = state.typingUsers.filter(
				(user) => user.user_id !== action.payload.user_id
			);
			return {
				...state,
				typingUsers: [...filteredUsers, action.payload]
			};
		}
		case 'UNSET_TYPING_USERS': {
			const filteredUsers = state.typingUsers.filter(
				(user) => user.user_id !== action.payload.user_id
			);
			return {
				...state,
				typingUsers: filteredUsers
			};
		}
		case 'SET_MSG_HAS_BEEN_INDICATED':
			return {
				...state,
				isMsgHasBeenIndicated: action.payload
			};
		case 'SET_IS_LAST_PAST_FETCHED': {
			return {
				...state,
				isLastPageFetched: action.payload
			};
		}
		case 'SET_IS_FIRST_PAST_FETCHED': {
			return {
				...state,
				isFirstPageFetched: action.payload
			};
		}
		case 'SET_LAST_READ_MESSAGE_ID': {
			return {
				...state,
				lastReadMessageId: action.payload
			};
		}
		case 'SET_MENTION_USERS_LIST': {
			return {
				...state,
				mentionUsersList: action.payload
			};
		}
		case 'IS_HANDSHAKE_PROCESSED': {
			return {
				...state,
				isChatHandshakeProcessed: action.payload
			};
		}
		default:
			return state;
	}
};
