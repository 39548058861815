import Select from 'react-select';
import { DropdownIndicator } from '../DropdownIndicator';
import { caseTypeSelectCustomStyles } from '../CaseTypeSelectMocksAndTypes';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import { useLanguageSelect } from 'shared/hooks/useLanguageSelect';
import { LanguageSelectCustomComponents } from './components';
import Loader from '../../../Loader';


type Props = {
	isRegistering: boolean;
	customLanguageSelect?: {
		changeLanguage: (language: string) => void;
		language: string;
	};
};

const selectStyles = {
	...caseTypeSelectCustomStyles,
	menu: (base: any) => ({ ...base, width: 150, right: -50, zIndex: 20 }),
	valueContainer: (base: any) => ({ ...base, padding: 0, width: '100%' }),
	singleValue: (base: any) => ({
		...base,
		width: '100%',
		overflow: 'visible',
		textOverflow: 'unset'
	}),
	indicatorsContainer: (base: any) => ({ ...base, display: 'none' })
};

function LanguageSelectInner({ isRegistering, customLanguageSelect }: Props) {
	const {
		isLanguageLoading,
		LANGUAGE_OPTIONS,
		changedLanguage,
		defaultLanguage,
		handleLanguageChange,
		isSwitchingLanguage
	} = useLanguageSelect({ isRegistering, customLanguageSelect });

	return (
		<div
			className={clsx(
				'relative',
				{ 'react-select-dropdown': !customLanguageSelect },
				{ 'ml-4 w-16': !isMobile, 'ml-2 w-16': isMobile }
			)}
		>
			{isSwitchingLanguage && !isRegistering && <Loader className="z-50" tiled />}
			<Select
				isLoading={isLanguageLoading}
				className={clsx({ 'case-type-select-container': !customLanguageSelect })}
				classNamePrefix="case-type-select"
				options={LANGUAGE_OPTIONS}
				components={{
					DropdownIndicator,
					SingleValue: LanguageSelectCustomComponents.SingleValue,
					Option: LanguageSelectCustomComponents.Option
				}}
				value={changedLanguage ?? defaultLanguage}
				onChange={handleLanguageChange()}
				isSearchable={false}
				styles={selectStyles}
			/>
		</div>
	);
}

export const LanguageSelect = (props: Props) => {
	return (
		<ErrorBoundary isCompact>
			<LanguageSelectInner {...props} />
		</ErrorBoundary>
	);
};
