const {
	REACT_APP_API_ROOT,
	REACT_APP_URL,
	REACT_APP_API_WS_DEV,
	REACT_APP_GMAP_API_KEY,
	REACT_APP_CLIENT_APP_URL,
	REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
	REACT_APP_GOOGLE_RECAPTCHA_SECRET_KEY
} = process.env;

const config = {
	CLIENT_APP_URL: REACT_APP_CLIENT_APP_URL,
	API_ROOT: REACT_APP_API_ROOT,
	REACT_APP_URL: REACT_APP_URL,
	DEFAULT_LANGUAGE: 'en',
	PUBLIC: process.env.PUBLIC_URL,
	PUBLIC_ASSETS: `${process.env.PUBLIC_URL}/assets/`,
	API_WS_DEV: REACT_APP_API_WS_DEV as string,
	GMAP_API_KEY: REACT_APP_GMAP_API_KEY,
	STATUS_DROPPABLE_ID: 'statuses',
	STATUS_DROPPABLE_TYPE: 'column',
	CASES_DROPPABLE_TYPE: 'cases',
	CLIENT: 'Client',
	STAFF: 'Staff',
	RECAPTCHA_SITE_KEY: REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY ?? '',
	RECAPTCHA_SECRET_KEY: REACT_APP_GOOGLE_RECAPTCHA_SECRET_KEY ?? ''
};

export default config;
