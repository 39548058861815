import { IMessage, IMetaAll, IRootState, QueryType } from 'shared/interfaces';
import { useChatInputContext } from 'shared/contexts/ChatInputContext';
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';
import { default as dayjs } from 'dayjs';
import { loadEntitiesSuccess } from 'store/actions/entities';
import { formSuccess, loadAllSuccess } from 'modules/entity/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useChatContext } from 'shared/contexts/ChatContext';
import Actions from 'store/actions';
import Selectors from 'modules/entity/selectors';
import { useUpdateEntities } from '../useUpdateEntities';
import { useAppState } from '../../state';
import { useDraftFunctions } from './draft/useDraftFunctions';
import { useChatFunctions } from './useChatFunctions';
import { useCallback, useEffect, useRef } from 'react';
import { useSocketHelpers } from '../../userSocket/hooks/useSocketHelpers';
import { useQueryParams } from '../useQueryParams';
import { toUpperCase, sanitizeMentionMsg } from 'shared/services';
import { SocketEventTypes } from 'shared/typings/socketEvent.types';
import { mentionEventChannel } from '../../../eventChannels/mention';
import { useChatMentionUpdate } from '../../components/Chat/hooks/useChatMentionUpdate';
import { getChatMessagesEntityName } from '../../components/Chat/utils';
import { EditingMessageType } from '../../contexts/reducers/chatInputReducer';

const getAll = Selectors.getAll();
const UpdateEntities = Actions.entities.updateEntitiesSuccess;

type Types = {
	sendJsonMessage?: SendJsonMessage;
	entityId?: number;
	entityType?: string;
};

// type ReloadChat = {
// 	entityId: number;
// 	isCase: boolean;
// 	entityName: string;
// 	url: string;
// };

export type MsgPayload = {
	type: string;
	preview?: {
		name: string;
		imagePreviewUrl: string | ArrayBuffer | null;
	};
	size?: string;
	uuid?: string;
	replied_to_id?: null | number;
	replied_to?: null | {
		file_type: string;
		from_user_name: string;
		text: string;
	};
	file?: any;
	msgSendingChatId?: number;
	file_type?: any;
	edited: boolean;
	is_draft?: boolean;
	scheduled_to?: number | null;
};

export type ReadAction = {
	isLast: boolean;
	messageId: number | undefined;
	entityId: number;
	entityType: string;
	companyId: number | undefined;
	appDeviceUuid: string | null;
};

export type TypingPayload = {
	type: string;
	entity_id: number;
	entity_type: string;
};

export function useChatMessage({ sendJsonMessage, entityId, entityType }: Types) {
	const {
		message,
		editingMessage,
		setEditingMessage,
		setMessage,
		setAudioUploadProgress,
		messageScheduleDate,
		setMessageScheduleDate,
		tempMessageEvent,
		setTempMessageEvent
	} = useChatInputContext();
	const { userData } = useAuth();
	const dispatch = useDispatch();
	const {
		isInBottom,
		socketState,
		setTypingUsers,
		unsetTypingUsers,
		virtuosoRef,
		setLastReadMessageId,
		isScheduledChat,
		setIsChatHandshakeProcessed
	} = useChatContext();
	const { updateEntities } = useUpdateEntities();
	const { activeDraft, setActiveDraft, companyId } = useAppState();
	const { getLS, updateEntityDraft, removeLS } = useDraftFunctions();
	const { incrementChatMentions } = useChatMentionUpdate();
	const { deleteMessage } = useChatFunctions();
	const { incrementMsgUnread } = useSocketHelpers();
	const { query } = useQueryParams();
	const draftObj = getLS();
	const isInBottomRef = useRef(isInBottom);
	const entityName = getChatMessagesEntityName({
		isScheduled: isScheduledChat,
		entityId,
		entityType
	});

	const entitiesObjectInState = useSelector((state: IRootState) => state.entities);
	const entityObjectInState = useSelector((state: IRootState) => state.entity);
	const messageEntitiesObject = entitiesObjectInState[`${entityType}Messages`];
	const messageEntityObject = entityObjectInState[`${entityType}Messages`]?.[entityName];

	const statusId = query ? query?.status : null;

	const messages = useSelector((state) =>
		getAll(state, {
			entity: `${entityType}Messages`,
			name: entityName
		})
	);

	useEffect(() => {
		isInBottomRef.current = isInBottom;
	}, [isInBottom]);

	// const areRestMessagesLoaded = messages.items.length;
	const areRestMessagesLoaded = messages.meta;

	function onMessage(event: MessageEvent<any>) {
		const messageObj = JSON.parse(event.data);
		// isCurrentChat - It means any chat message must not be received in wrong chat
		const isCurrentChat = +messageObj.entity_id === entityId;
		//For typing info
		let lastTyping: { [key: string]: any } = {};
		const messageType = toUpperCase(messageObj.type);

		//WHEN RECEIVE HANDSHAKE RESPONSE
		if (messageType === SocketEventTypes.HANDSHAKE) {
			setIsChatHandshakeProcessed(true);
		}

		/**--- When we receive new message ----**/
		if (
			(messageType === SocketEventTypes.NEW_MESSAGE ||
				messageType === SocketEventTypes.STATUS_CHANGED ||
				messageType === SocketEventTypes.PRIORITY_CHANGED ||
				messageType === SocketEventTypes.REQUEST_MANAGER ||
				messageType === SocketEventTypes.REQUEST_UPDATE) &&
			isCurrentChat
		) {
			const payload = {
				...messageObj,
				custom_uuid: messageObj.custom_uuid ?? messageObj.sent_time.toString()
			} as IMessage;
			//Commit message to UI
			saveMessageToStore(payload, false);

			if (
				messageObj.message_type === SocketEventTypes.ADD_MEMBER ||
				SocketEventTypes.REMOVE_MEMBER ||
				SocketEventTypes.LEFT_MEMBER
			) {
				mentionEventChannel.emit('onMembersUpdate');
			}

			//increment unread count if it is not our message
			if (!isInBottomRef.current && messageObj.from_user.id !== userData.id) {
				incrementMsgUnread(messageObj.entity_type, messageObj.entity_id);
			}

			//for own messages, we set last_read_message on real time.3379's case
			if (messageObj.from_user_id === userData.id && isCurrentChat) {
				setLastReadMessageId(messageObj.message_id);

				//we need to update entity's last_read_message for user's own sent message to properly show unread messages inside chat
				/* Use cases: - when user send message to the chat, and then closes it through ESC keyboard
											- when user send message, then closes chat through browser's native back button
											- when user send message, then switches chat by switching cases/groups without closing it
				*  */
				updateEntities({
					entity: entityType === 'case' ? 'cases' : 'ims-chats',
					entityId: entityId,
					updatingData: {
						last_read_message: messageObj.message_id
					}
				});
			}

			//Update last message text in case item in Case list
			if (isCurrentChat) updateLastCaseMessage(messageObj);

			//Only for audio message
			if (payload.file_type === 'AUDIO') setAudioUploadProgress({}); // just to be sure to remove overlay UI when socket responded
		}
		//increment mention count in active chat if that message is unread
		if (messageType === SocketEventTypes.USER_TAGGED) {
			const { custom_uuid, message_id, entity_id } = messageObj;
			const isCurrentUserMentioned = messageObj?.user_ids?.includes(userData.id);
			const currentMsgInStore = messageEntitiesObject?.[custom_uuid] as IMessage;
			const isUnreadMessage = !currentMsgInStore?.read_time;

			if (isCurrentUserMentioned && isUnreadMessage && companyId) {
				incrementChatMentions({
					entityId: entity_id,
					messageId: message_id,
					currentMsgCompanyId: companyId
				});
			}
		}

		function sendDeleteDraft() {
			const payload = {
				entity_id: entityId,
				type: 'delete_draft_message'
			};

			sendJsonMessage?.(payload);
		}

		/**--- When we receive edit_message event ----**/
		if (messageType === SocketEventTypes.EDIT_MESSAGE && isCurrentChat) {
			const payload = {
				...messageObj,
				translated_text: null,
				original_text: null,
				is_translation_shown: false
			} as IMessage;
			saveMessageToStore(payload, true);

			if (!payload.text) {
				if (activeDraft || (entityId && entityId in draftObj)) {
					updateEntityDraft('reset');
					setActiveDraft(null);
					removeLS(entityId);
					sendDeleteDraft();
					setMessage('');
				}
				setEditingMessage(null);
			} //remove editing mode after socket responded

			if (payload.file_type === 'AUDIO') setAudioUploadProgress({}); // just to be sure to remove overlay UI when socket responded
		}

		/**--- When we receive reacted_message event ----**/
		if (messageType === SocketEventTypes.REACTED_MESSAGE && isCurrentChat) {
			const payload = { ...messageObj } as IMessage;

			updateEntities({
				entity: `${entityType}Messages`,
				entityId: payload.custom_uuid,
				updatingData: { reacted_users: payload.reacted_users }
			});
		}

		/** --- When we receive, replied_to_deleted event --- **/
		if (messageType === SocketEventTypes.REPLIED_MESSAGE_DELETED) {
			messageObj?.messages_uuids?.forEach((uuid: string) => {
				updateEntities({
					entity: `${entityType}Messages`,
					entityId: uuid,
					updatingData: {
						replied_to: {
							...messageObj?.replied_to,
							is_deleted: true
						}
					}
				});
			});
		}

		/**--- Updating read state ----**/
		//Update read state only for own messages
		if (
			messageType === SocketEventTypes.MESSAGE_READ &&
			isCurrentChat
			// && messageObj?.user_id !== userData.id
		) {
			//Update read_time for single message
			updateEntityField(
				{
					read_time: Date.now()
				},
				`${entityType}Messages`,
				messageObj.custom_uuid
			);

			//TODO: i think we won't need below code as we update read_time after reading every unread message in messageVisibleHandler,
			// remove it after 3321's fix is successful
			//Update read_time of all previous messages if they are unread, that is, if they have no read_time
			/*if (messageObj.app_id && !messages?.items[messages?.items.length - 2]?.read_time) {
				messages?.items?.forEach((item: IMessage) => {
					if (!item.read_time) {
						updateEntityField(
							{
								read_time: Date.now()
							},
							`${entityType}Messages`,
							item.custom_uuid
						);
					}
				});
			}*/
		}
		if (messageType === SocketEventTypes.TYPING && isCurrentChat) {
			lastTyping = {
				...lastTyping,
				[messageObj.user_id]: {
					...messageObj,
					lastTime: new Date().getTime()
				}
			};

			if (+messageObj.entity_id === entityId) typing();
		}

		if (messageType === SocketEventTypes.DELETE_MESSAGE && isCurrentChat) {
			deleteMessage(messageObj);
		}

		function typing() {
			// eslint-disable-next-line prefer-const
			let timer;
			setTypingUsers(messageObj);
			clearTimeout(timer);
			timer = setTimeout(() => {
				Object.keys(lastTyping).forEach((user: string) => {
					//Checking if typing actions has been stopped
					if (lastTyping[user].lastTime + 3000 < new Date().getTime()) {
						unsetTypingUsers(lastTyping[user]);
					}
				});
			}, 3000);
		}
	}

	function getMessageType(editingMessage: EditingMessageType, messageScheduledDate?: number) {
		if (messageScheduledDate && editingMessage) {
			return 'edit_scheduled_message';
		}

		if (messageScheduledDate && !editingMessage) {
			return 'scheduled_message';
		}

		if (editingMessage) {
			return 'edit_message';
		}

		return 'message';
	}

	type BaseMessageType = {
		entity_id?: number;
		entity_type?: string;
		custom_uuid?: string;
		replied_to_id?: number | null;
		msgSendingChatId?: number;
		text: string;
		reacted_users: number[];
		id?: number;
		scheduled_to?: number;
	};
	function sendChatMessage(payload: MsgPayload) {
		const { type, uuid, replied_to_id, replied_to, is_draft, scheduled_to } = payload;
		// console.log('sendChatMessage payload', payload);
		const messageScheduledDate = scheduled_to ?? editingMessage?.scheduled_to;
		const baseMessage: BaseMessageType = {
			entity_id: entityId,
			entity_type: entityType,
			custom_uuid: editingMessage ? editingMessage.custom_uuid : uuid,
			replied_to_id: editingMessage ? editingMessage.replied_to_id : replied_to_id,
			msgSendingChatId: payload?.msgSendingChatId,
			text: type === 'text' ? sanitizeMentionMsg(message) : '',
			reacted_users: []
		};

		if (editingMessage) {
			baseMessage.id = editingMessage.id;
		}

		if (messageScheduledDate) {
			baseMessage.scheduled_to = messageScheduledDate;
		}

		const messageType = getMessageType(editingMessage, messageScheduledDate);

		let textMessagePayloadForSocket = {
			...baseMessage,
			type: messageType,
			is_draft: !!is_draft
		};

		const localMessagePayload = {
			//For immediately render in UI while socket sends response back
			...baseMessage,
			replied_to,
			from_user: userData,
			from_user_id: userData.id,
			sent_time: dayjs().unix(),
			type: 'new_message',
			id: null,
			file: null,
			file_type: '',
			edited: !!editingMessage,
			translated_text: null,
			original_text: null,
			is_translation_shown: false
		};

		if (messageScheduledDate) {
			localMessagePayload.edited = false;
		}

		const textMessagePayloadForSocketType = toUpperCase(textMessagePayloadForSocket.type);

		switch (type) {
			case 'text':
			case 'image': {
				// if (!areRestMessagesLoaded && socketState !== 'Open') return;
				if (type === 'text' && !message) {
					return;
				}
				const payloadData = {
					...localMessagePayload,
					text: type === 'text' ? sanitizeMentionMsg(message) : '',
					file_type: type === 'image' ? 'IMAGE' : '',
					file:
						payload.type === 'image'
							? {
									url: payload?.preview?.imagePreviewUrl,
									thumbnail: payload?.preview?.imagePreviewUrl,
									thumbnail_150: payload?.preview?.imagePreviewUrl,
									name: payload?.preview?.name
							  }
							: '',
					size: payload.size,
					msgSendingChatId: payload?.msgSendingChatId
				};

				switch (textMessagePayloadForSocketType) {
					case SocketEventTypes.MESSAGE:
					case SocketEventTypes.SCHEDULED_MESSAGE: {
						saveMessageToStore(payloadData, false);
						setTimeout(() => scrollChatToBottom(), 0);
						break;
					}

					case SocketEventTypes.EDIT_MESSAGE:
					case SocketEventTypes.EDIT_SCHEDULED_MESSAGE: {
						saveMessageToStore(payloadData, true);
						type === 'text' && setEditingMessage(null);
						break;
					}
				}

				break;
			}
			case 'audio': {
				if (!areRestMessagesLoaded && socketState !== 'Open') return;
				const payloadData = {
					...localMessagePayload,
					text: '',
					file_type: 'AUDIO',
					size: payload.size,
					msgSendingChatId: payload?.msgSendingChatId,
					file: editingMessage
						? messages?.items?.find(
								(msg: IMessage) => (msg.id ? msg.id : msg.message_id) === editingMessage.id
						  )
						: null,
					...{ ...(editingMessage ? { id: editingMessage.id } : {}) }
				};

				switch (textMessagePayloadForSocketType) {
					case SocketEventTypes.MESSAGE:
					case SocketEventTypes.SCHEDULED_MESSAGE: {
						saveMessageToStore(payloadData, false);
						setTimeout(() => scrollChatToBottom(), 0);
						break;
					}

					case SocketEventTypes.EDIT_MESSAGE:
					case SocketEventTypes.EDIT_SCHEDULED_MESSAGE: {
						saveMessageToStore(payloadData, true);
						setMessage('');
						break;
					}
				}

				break;
			}
			case 'file': {
				//This case directly sends to websocket. This case does not dispatch anything to redux.
				//This case handles only sending messages after files have been uploaded in useChatUpload hook

				const { file } = payload;

				let file_type = 'FILE';
				// let file_duration = file.file_duration;
				try {
					const MIME = file?.file?.type;
					const type = MIME.split('/')[0].toUpperCase();

					switch (type) {
						case 'AUDIO':
						case 'VIDEO':
						case 'IMAGE':
							file_type = type;
							break;
						default:
							file_type = 'FILE';
							break;
					}
				} catch (err) {
					// eslint-disable-next-line no-console
					console.error('chat file send', err);
				}

				// set(file.file.response, 'duration', file_duration);

				textMessagePayloadForSocket = {
					...textMessagePayloadForSocket,
					// @ts-ignore
					file: file.file.response,
					file_type
				};

				if (textMessagePayloadForSocketType !== SocketEventTypes.EDIT_MESSAGE) {
					scrollChatToBottom();
				}

				break;
			}

			default: {
				return null;
			}
		}

		const { text: msgText } = textMessagePayloadForSocket;

		const isSendingValidMessage = 'file_type' in textMessagePayloadForSocket || msgText.length;
		const checkIfEntityIsCorrect = entityId === textMessagePayloadForSocket.msgSendingChatId;

		// Check if message is valid | msgs have been downloaded | not sending to wrong chat | there is socket connection
		if (
			isSendingValidMessage &&
			// areRestMessagesLoaded &&
			checkIfEntityIsCorrect
			// && socketState === 'Open'
		) {
			sendJsonMessage?.(textMessagePayloadForSocket);
		}

		if (textMessagePayloadForSocketType !== SocketEventTypes.EDIT_MESSAGE) {
			scrollChatToBottom();
		}
	}

	function scrollChatToBottom() {
		virtuosoRef?.current?.scrollToIndex({
			index: 'LAST',
			align: 'center',
			behavior: 'auto'
		});
	}

	function saveMessageToStore(message: any, isUpdating = false) {
		const uuid = message.custom_uuid;
		const entitiesPayload = {
			[`${entityType}Messages`]: {
				[uuid]: message
			}
		};

		const entityName = getChatMessagesEntityName({
			isScheduled: Boolean(message?.scheduled_to),
			entityType,
			entityId
		});

		dispatch(loadEntitiesSuccess(entitiesPayload));
		dispatch(
			formSuccess({
				id: uuid,
				entity: `${entityType}Messages`,
				name: entityName,
				appendData: true,
				prependData: false,
				updateData: isUpdating,
				deleteData: false
			})
		);
		if (isInBottomRef.current) {
			scrollChatToBottom();
		}
	}

	function updateEntityField(
		data: { [key: string]: any },
		entity: string,
		entityId: number | string
	) {
		dispatch(
			UpdateEntities({
				entity,
				entityId: String(entityId),
				data
			})
		);
	}

	//todo: refactor removing no_unread_left field in case 2432, 2842, 2940, 2653 cases are resolved
	function sendReadAction({
		isLast,
		messageId,
		entityId,
		entityType,
		companyId,
		appDeviceUuid
	}: ReadAction) {
		const readInfo = {
			type: 'read',
			entity_id: entityId,
			entity_type: entityType,
			time: dayjs().unix(),
			message_id: messageId,
			// no_unread_left: isLast,
			company_id: companyId,
			app_id: appDeviceUuid,
			status_id: Number(statusId)
		};

		sendJsonMessage?.(readInfo);
	}

	//Send typing action via socket
	const sendTypingInfo = useCallback(
		(payload: TypingPayload) => {
			if (socketState === 'Open') {
				sendJsonMessage?.(payload);
			}
		},
		[socketState, sendJsonMessage]
	);

	function updateLastCaseMessage(messageObj: IMessage) {
		dispatch(
			UpdateEntities({
				entity: entityType === 'case' ? 'cases' : 'ims-chats',
				entityId: String(entityId),
				data: {
					last_message_text: messageObj.text,
					last_message_type: messageObj.file_type,
					last_message_author: {
						first_name: messageObj.from_user?.first_name,
						last_name: messageObj.from_user?.last_name
					},
					last_message_time: messageObj.sent_time
				}
			})
		);
	}

	function sendDeleteScheduledMessage(messageId: number) {
		const payload = {
			type: 'delete_scheduled_message',
			id: messageId
		};

		sendJsonMessage?.(payload);
	}

	function sendScheduledMessageNow(messageId: number, custom_uuid: string) {
		const payload = {
			type: 'scheduled_message_send_now',
			id: messageId
		};

		sendJsonMessage?.(payload);

		if (messageEntityObject) {
			const messageIdsAfterRemovedMessage = [
				...(messageEntityObject?.ids as string[])?.filter((id: string) => id !== custom_uuid)
			];

			dispatch(
				loadAllSuccess({
					ids: messageIdsAfterRemovedMessage,
					entity: `${entityType}Messages`,
					name: entityName,
					replaceIds: true,
					params: messageEntityObject?.params as QueryType,
					meta: {
						...messageEntityObject?.meta,
						count: (messageEntityObject?.meta?.count as number) - 1
					} as IMetaAll,
					appendData: false,
					prependData: false,
					infiniteScroll: false,
					isUniq: false
				})
			);
		}
	}

	return {
		onMessage,
		sendChatMessage,
		sendReadAction,
		scrollChatToBottom,
		messages,
		sendTypingInfo,
		areRestMessagesLoaded,
		sendDeleteScheduledMessage,
		sendScheduledMessageNow
	};
}
