import {
	createContext,
	FC,
	useContext,
	useEffect,
	useState,
	useRef,
	useCallback,
	useMemo
} from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import config from 'config';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useAppState } from 'shared/state';
import { useChatMessage } from 'shared/hooks';
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';
import { useChatContext } from './ChatContext';

type CaseSocketContextT = {
	closeSocket: () => void;
	sendJsonMessage: SendJsonMessage;
	connectionStatus?: string;
};

const ChatSocketContext = createContext<CaseSocketContextT>(null!);

const socketApiEndpoint = config.API_WS_DEV;

type Props = {
	entityId: number;
	entityType: string;
};
export const ChatSocketProvider: FC<Props> = ({ children, entityId, entityType }) => {
	const { token } = useAuth();
	const { chatUuid } = useAppState();
	const { onMessage } = useChatMessage({ entityId, entityType });
	// const { message } = useChatInputContext();
	const { setSocketState,setIsChatHandshakeProcessed } = useChatContext();
	const timerRef = useRef<any>(null);
	const [socketUrl, setSocketUrl] = useState<{ [key: string]: string }>({
		[entityId]: socketApiEndpoint
	});
	const { readyState, sendJsonMessage, getWebSocket } = useWebSocket(
		socketUrl[entityId] ? `${socketUrl[entityId]}?token=${token}` : null,
		{
			retryOnError: true,
			reconnectAttempts: 120,
			reconnectInterval: 5000,
			onReconnectStop: () => {
				window.location.reload();
			},
			shouldReconnect: (closeEvent) => {
				return !!entityId;
				//TODO Reconnect if internet is available
			},
			onOpen: (event) => {
				sendJsonMessage({
					type: 'handshake',
					entity_id: entityId,
					entity_type: entityType
				});

				timerRef.current = setInterval(() => {
					sendJsonMessage({ type: 'ping', key: `${entityType}_${entityId}` });
				}, 300000);
			},
			onClose: (event) => {
				console.log('socket closed');
				clearInterval(timerRef.current);
				setIsChatHandshakeProcessed(false);
			},
			onError: (event) => {
				console.log('socket error', event);
			},
			onMessage,
			share: false
		}
	);

	// const connectionStatus = {
	// 	[ReadyState.CONNECTING]: 'Connecting',
	// 	[ReadyState.OPEN]: 'Open',
	// 	[ReadyState.CLOSING]: 'Closing',
	// 	[ReadyState.CLOSED]: 'Closed',
	// 	[ReadyState.UNINSTANTIATED]: 'Uninstantiated'
	// }[readyState];

	const connectionStatus = useMemo(
		() =>
			({
				[ReadyState.CONNECTING]: 'Connecting',
				[ReadyState.OPEN]: 'Open',
				[ReadyState.CLOSING]: 'Closing',
				[ReadyState.CLOSED]: 'Closed',
				[ReadyState.UNINSTANTIATED]: 'Uninstantiated'
			}[readyState]),
		[readyState]
	);

	useEffect(() => {
		setSocketState(connectionStatus);
	}, [connectionStatus]);

	const closeSocket = useCallback(() => {
		getWebSocket()?.close();
	}, [getWebSocket]);
	// useEffect(() => {
	// 	return () => {
	// 		clearInterval(timerRef.current);
	// 	};
	// }, []);

	//CASE: 4376 - i included entityId as dependency, bcs creating new group and opening it while another group chat is open caused socket to not connecting properly
	useEffect(() => {
		if (getWebSocket()) {
			getWebSocket()?.close();
		}
		setSocketUrl({ ...socketUrl, [entityId]: socketApiEndpoint });
	}, [chatUuid, entityId]);

	const contextValues = {
		closeSocket,
		sendJsonMessage,
		connectionStatus
	};

	return <ChatSocketContext.Provider value={contextValues}>{children}</ChatSocketContext.Provider>;
};

export function useChatSocket() {
	const context = useContext(ChatSocketContext);

	if (!context) throw new Error('useChatSocket must be used within the ChatSocketProvider');

	return context;
}
