import { useEffect, useMemo, useState } from 'react';
import { useAxios } from './useAxios';
import { setDayJsLocale } from 'shared/services/utils/setLibLocales';
import { storage } from 'shared/services';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'shared/state';
import Actions from 'store/actions';
import { useDispatch } from 'react-redux';
import { LanguageOption, LanguageType } from '../typings/localization.types';

const SetCurrentLanguageAction = Actions.system.ChangeLanguage;

type TLanguageSelect = {
	isLanguageLoading: boolean;
	changedLanguage: LanguageType | null;
	LANGUAGE_OPTIONS: LanguageType[];
	handleLanguageChange: () => (option: LanguageOption) => void;
	defaultLanguage: LanguageType;
	isSwitchingLanguage:boolean;
	languageSwitchSideEffects:(option: LanguageOption) => void;
};

type TProps = {
	isRegistering: boolean;
	customLanguageSelect?: {
		changeLanguage: (language: string) => void;
		language: string;
	};
};

export function useLanguageSelect({
	customLanguageSelect,
	isRegistering
}: TProps): TLanguageSelect {
	const [changedLanguage, setChangedLanguage] = useState<LanguageType | null>(null);
	const { setLanguage } = useAppState();
	const { i18n } = useTranslation();
	const selectedLanguage = storage.get('i18nextLng');
	const dispatch = useDispatch();

	const { fetchData: changeAccountLanguage, loading:isSwitchingLanguage } = useAxios({
		url: '/my/profile/',
		method: 'patch',
		dynamicValues: true,
		cb: {
			success: (data, args) => {
				const { accountLanguageChangeSuccessHandler } = args?.[0];

				accountLanguageChangeSuccessHandler?.();
			},
			error: (error) => {}
		}
	});

	function setCurrentLanguageInRedux(lang:string) {
		dispatch(SetCurrentLanguageAction(lang))
	}

	const {
		response: languageOptionsData,
		fetchData: fetchLanguageData,
		loading: isLanguageLoading
	} = useAxios({
		url: '/system-languages/'
	});

	const LANGUAGE_OPTIONS = useMemo(
		() =>
			(languageOptionsData as any)?.results?.map((language: LanguageType) => ({
				value: language?.id,
				label: language?.name,
				icon: language?.icon,
				shortLabel: language?.short_name,
				is_default: language?.is_default
			})),
		[languageOptionsData]
	);

	useEffect(() => {
		(async () => {
			await fetchLanguageData();
		})();
	}, []);

	useEffect(() => {
		if (customLanguageSelect) {
			setChangedLanguage(
				LANGUAGE_OPTIONS?.find(
					(option: LanguageOption) => option?.shortLabel === customLanguageSelect?.language
				)
			);
		} else {
			setChangedLanguage(
				LANGUAGE_OPTIONS?.find((option: LanguageOption) => option?.shortLabel === selectedLanguage)
			);
		}
	}, [customLanguageSelect, selectedLanguage, LANGUAGE_OPTIONS]);

	function languageSwitchSideEffects(option:LanguageOption) {

		const currentLangStr = option?.shortLabel?.toLowerCase();
		if(currentLangStr) {
			setCurrentLanguageInRedux(currentLangStr);
		}

		setLanguage(option as any);
		i18n.changeLanguage(option?.shortLabel);
		setDayJsLocale(option?.shortLabel as any);
		//setYupLocale(option?.shortLabel as any);
	}

	const handleLanguageChange = () => (option: LanguageOption) => {
		if (option) {
			if (customLanguageSelect) {
				customLanguageSelect?.changeLanguage?.(option?.shortLabel);
				return;
			}

			storage.set('selected_language', JSON.stringify(option));

			if (isRegistering) {
				languageSwitchSideEffects(option);
				return;
			}

			changeAccountLanguage({
				values: {
					language: option?.value
				},
				accountLanguageChangeSuccessHandler: () => {

					languageSwitchSideEffects(option);
				}
			});
		}
	};

	const defaultLanguage = LANGUAGE_OPTIONS?.find((option: LanguageOption) => option.is_default);

	return {
		isLanguageLoading,
		defaultLanguage,
		LANGUAGE_OPTIONS,
		changedLanguage,
		handleLanguageChange,
		isSwitchingLanguage,
		languageSwitchSideEffects
	};
}
