import React, {
	createContext,
	useReducer,
	useContext,
	useRef,
	useMemo,
	useCallback,
	useEffect,
	RefObject
} from 'react';
import { VirtuosoHandle } from 'react-virtuoso';

import {
	chatContextReducer,
	ChatStateType,
	IncomingTyping,
	initialChatState,
	MentionUserEntity
} from './reducers/chatContextReducer';
import { useAppState } from '../state';
import { useQueryParams } from '../hooks';
import { getChatMessagesEntityName } from '../components/Chat/utils';
import { storage } from '../services';
import { ChatContextActionNames } from './types/chatContextTypes';

const MESSAGES_FETCH_SIZE = 25;

type ContextTypes = {
	isBottomLoading: boolean;
	setBottomLoading: (loading: boolean) => void;
	entityId: number;
	allowToLoad: boolean;
	/* Note: direction: 'next' - fetches top messages of the chat
	direction: 'previous' - fetches bottom message of the chat*/
	direction: string | null;
	isSetSearchReply: boolean;
	replyLoading: boolean;
	scrollToMsgId: number | null;
	setScrollToMsgId: (msgId: number | null) => void;
	setAllowToLoad: (isAllowed: boolean) => void;
	setDirection: (direction: string | null) => void;
	setReplyLoading: (isLoading: boolean) => void;
	setIsSetSearchReply: (isLoading: boolean) => void;
	setScrollPosition: (isInBottom: boolean) => void;
	setSocketState: (chatState: string) => void;
	messagesLimit: number;
	state: ChatStateType;
	virtuosoRef: RefObject<VirtuosoHandle> | undefined;
	onGoingAudioPlayer: HTMLAudioElement;
	isInBottom: boolean;
	entityType: string;
	entityName: string;
	socketState: string;
	typingUsers: IncomingTyping[];
	setTypingUsers: (user: IncomingTyping) => void;
	unsetTypingUsers: (user: IncomingTyping) => void;
	setIsMsgHasBeenIndicated: (isMsgHasBeenIndicated: boolean) => void;
	isMsgHasBeenIndicated: boolean;
	setIsLastPageFetched: (isLast: boolean) => void;
	setIsFirstPageFetched: (isFirst: boolean) => void;
	isLastPageFetched: boolean;
	isFirstPageFetched: boolean;
	setScrollToMessageHandler: (messageId: number | null) => void;
	setLastReadMessageId: (messageId: number | null) => void;
	lastReadMessageId: number | null;
	scrollToBottom: () => void;
	setMentionUsersList: (users: MentionUserEntity[]) => void;
	mentionUsersList: MentionUserEntity[];
	isScheduledChat: boolean;
	isChatHandshakeProcessed:boolean;
	setIsChatHandshakeProcessed:(isProcessed:boolean) => void;
};

const ChatContext = createContext<ContextTypes>(null!);

type Props = {
	entityId: number;
	entityType: 'case' | 'ims';
};

export const ChatContextProvider: React.FC<Props> = ({ children, entityId, entityType }) => {
	const [state, dispatch] = useReducer(chatContextReducer, initialChatState);
	const { searchQuery, groupMessageSearchQuery, isLastPageLoadedTriggered } = useAppState();
	const virtuosoRef = useRef<VirtuosoHandle>(null);
	const { query } = useQueryParams();
	const onGoingAudioPlayer = useMemo(() => new Audio(), []);

	const {
		allowToLoad,
		direction,
		isSetSearchReply,
		replyLoading,
		scrollToMsgId,
		isInBottom,
		socketState,
		typingUsers,
		isMsgHasBeenIndicated,
		isLastPageFetched,
		isFirstPageFetched,
		lastReadMessageId,
		mentionUsersList,
		isBottomLoading,
		isChatHandshakeProcessed
	} = state;
	const isScheduledChat = useMemo(
		() => query?.isScheduledChat === 'true',
		[query?.isScheduledChat]
	);
	const entityName = getChatMessagesEntityName({
		isScheduled: isScheduledChat,
		entityType,
		entityId
	});

	const isInBottomCached = useMemo(() => isInBottom, [isInBottom]);

	const setBottomLoading = useCallback((loading: boolean) => {
		dispatch({ type: ChatContextActionNames.SET_BOTTOM_LOADING, payload: loading });
	}, []);

	const setAllowToLoad = useCallback(
		(isAllowed: boolean) => dispatch({ type: 'ALLOW_LOAD', payload: isAllowed }),
		[]
	);

	const setDirection = useCallback(
		(direction: string | null) => dispatch({ type: 'SET_DIRECTION', payload: direction }),
		[]
	);

	const setReplyLoading = useCallback(
		(isLoading: boolean) => dispatch({ type: 'SET_REPLY_LOADING', payload: isLoading }),
		[]
	);
	const setIsSetSearchReply = useCallback((isSetSearchReply: boolean) => {
		dispatch({ type: 'SET_SEARCH_REPLY', payload: isSetSearchReply });
	}, []);
	const setScrollToMsgId = useCallback(
		(msgId: number | null) => dispatch({ type: 'SET_SCROLL_TO_MSG_ID', payload: msgId }),
		[]
	);
	const setScrollPosition = useCallback(
		(isInBottom: boolean) => dispatch({ type: 'SET_IS_SCROLL_IN_BOTTOM', payload: isInBottom }),
		[]
	);
	const setSocketState = useCallback(
		(chatState: string) => dispatch({ type: 'SET_CHAT_SOCKET_STATE', payload: chatState }),
		[]
	);
	const setTypingUsers = useCallback(
		(user: IncomingTyping) => dispatch({ type: 'SET_TYPING_USERS', payload: user }),
		[]
	);
	const unsetTypingUsers = useCallback(
		(user: IncomingTyping) => dispatch({ type: 'UNSET_TYPING_USERS', payload: user }),
		[]
	);
	const setIsMsgHasBeenIndicated = useCallback(
		(IsMsgHasBeenIndicated: boolean) =>
			dispatch({ type: 'SET_MSG_HAS_BEEN_INDICATED', payload: IsMsgHasBeenIndicated }),
		[]
	);

	const setIsLastPageFetched = useCallback(
		(isLast: boolean) => dispatch({ type: 'SET_IS_LAST_PAST_FETCHED', payload: isLast }),
		[]
	);

	const setIsFirstPageFetched = useCallback(
		(isFirst: boolean) => dispatch({ type: 'SET_IS_FIRST_PAST_FETCHED', payload: isFirst }),
		[]
	);
	const setLastReadMessageId = useCallback(
		(messageId: number | null) =>
			dispatch({ type: 'SET_LAST_READ_MESSAGE_ID', payload: messageId }),
		[]
	);

	const setMentionUsersList = useCallback(
		(users: MentionUserEntity[]) => dispatch({ type: 'SET_MENTION_USERS_LIST', payload: users }),
		[]
	);

	const setScrollToMessageHandler = useCallback(
		(msgId) => {
			const messageElement = msgId && document.querySelector(`#message-${msgId}`);

			//searchQuery.length - if search is active we must load replied or pinned message page again
			if (!messageElement || searchQuery.length) {
				setTimeout(() => {
					setIsLastPageFetched(false);
				}, 500);
			}
			if (msgId) {
				setScrollToMsgId(msgId);
			}
		},
		[setIsLastPageFetched, setScrollToMsgId, searchQuery]
	);

	const setIsChatHandshakeProcessed = useCallback(
		(isProcessed: boolean) => dispatch({ type: 'IS_HANDSHAKE_PROCESSED', payload:isProcessed }),
		[]
	);

	//ReSet lastPageFetched variable to enable to load next pageof the group chat on after scrolling to searched message
	useEffect(() => {
		if (groupMessageSearchQuery.length && isLastPageLoadedTriggered) {
			setIsLastPageFetched(false);
		}
	}, [groupMessageSearchQuery, isLastPageLoadedTriggered]);

	//Case: 3644 - Stopping and removing ongoing audio player from DOM
	useEffect(() => {
		return () => {
			setTimeout(() => {
				onGoingAudioPlayer.pause();
				onGoingAudioPlayer.remove();
				storage.remove('audioContinueTime');
			}, 0);
		};
	}, [onGoingAudioPlayer]);

	const scrollToBottom = useCallback(() => {
		virtuosoRef?.current?.scrollToIndex({
			index: 'LAST',
			align: 'end',
			behavior: 'auto',
			offset: 100
		});
	}, []);

	const contextValues = useMemo(
		() => ({
			entityId,
			allowToLoad,
			direction,
			setAllowToLoad,
			setDirection,
			setReplyLoading,
			isSetSearchReply,
			replyLoading,
			setIsSetSearchReply,
			setScrollToMsgId,
			scrollToMsgId,
			messagesLimit: MESSAGES_FETCH_SIZE,
			state,
			isInBottom: isInBottomCached,
			setScrollPosition,
			entityType,
			entityName,
			socketState,
			setSocketState,
			typingUsers,
			setTypingUsers,
			unsetTypingUsers,
			setIsMsgHasBeenIndicated,
			isMsgHasBeenIndicated,
			setIsLastPageFetched,
			setIsFirstPageFetched,
			isLastPageFetched,
			isFirstPageFetched,
			setScrollToMessageHandler,
			setLastReadMessageId,
			lastReadMessageId,
			virtuosoRef,
			scrollToBottom,
			mentionUsersList,
			setMentionUsersList,
			isScheduledChat,
			onGoingAudioPlayer,
			setBottomLoading,
			isBottomLoading,
			isChatHandshakeProcessed,
			setIsChatHandshakeProcessed,
		}),
		[
			entityId,
			allowToLoad,
			direction,
			setAllowToLoad,
			setDirection,
			setReplyLoading,
			isSetSearchReply,
			replyLoading,
			setIsSetSearchReply,
			setScrollToMsgId,
			scrollToMsgId,
			state,
			isInBottomCached,
			setScrollPosition,
			entityType,
			entityName,
			socketState,
			setSocketState,
			typingUsers,
			setTypingUsers,
			unsetTypingUsers,
			setIsMsgHasBeenIndicated,
			isMsgHasBeenIndicated,
			setIsLastPageFetched,
			setIsFirstPageFetched,
			isLastPageFetched,
			isFirstPageFetched,
			setScrollToMessageHandler,
			setLastReadMessageId,
			lastReadMessageId,
			virtuosoRef,
			scrollToBottom,
			mentionUsersList,
			setMentionUsersList,
			isScheduledChat,
			onGoingAudioPlayer,
			setBottomLoading,
			isBottomLoading,
			isChatHandshakeProcessed,
			setIsChatHandshakeProcessed,
		]
	);

	return <ChatContext.Provider value={contextValues}>{children}</ChatContext.Provider>;
};

export const useChatContext = () => {
	const context = useContext(ChatContext);

	if (!context) throw new Error('useChatContext must be used within the CaseContextProvider');

	return context;
};
